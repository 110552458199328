import React, { useState, useCallback } from 'react';
import { makeStyles, Box, Divider } from '@material-ui/core';
import Body1 from './typographys/Body1';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import DialogCheckPoint from './dialogs/DialogCheckPoint';
import isEmpty from '../../utils/isEmpty';
import MeetingPointNameAndStatus from './MeetingPointNameAndStatus';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  divider: {
    padding: '0px 10px',
  },
  point: {
    padding: '8px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  theme: {
    padding: '8px 10px',
    backgroundColor: theme.palette.neutrals[2],
  },
  lastPoint: {
    marginBottom: '20px',
  },
  themeBox: {
    marginBottom: '12px',
  },
}));

const ThemeList = ({ themes, isMeetingDone, users, isGroup }) => {
  const classes = useStyles();
  const [selectedPoint, setSelectedPoint] = useState({});
  const { id } = useParams();

  const changePoint = useCallback(
    point => e => {
      e.preventDefault();
      setSelectedPoint({
        ...point,
        internalLink: point.internal_link || '',
        internalLinkText: point.internal_link_text || '',
        organicUnit: point.organic_unit,
        hasBudget: point.has_budget,
        budgetNumber: point.budget_number,
        // budgetValue: point.budget_value,
        reuniaoExecutivaAssembleiaMunicipalFlow: point.reuniao_executiva_assembleia_municipal_flow,
        discussAM: point.discuss_am,
        meetingPointStatus: point.meeting_point_status,
        meetingUsers: users,
        meetingIsGroup: isGroup,
        meetingId: id,
      });
    },
    [users, isGroup, id]
  );

  return (
    <>
      {themes.map(theme => (
        <Box key={theme.id} className={classes.themeBox}>
          <Box className={classes.theme}>
            <Body1>{theme.name}</Body1>
          </Box>
          {theme.points && theme.points.length > 0 ? (
            theme.points.map((point, idxP) => (
              <React.Fragment key={point.id}>
                <Box
                  className={clsx(classes.point, {
                    [classes.lastPoint]: idxP + 1 === theme.points.length,
                  })}
                >
                  <MeetingPointNameAndStatus
                    identifierCode={point.identifier_code}
                    meetingPointStatus={point.meeting_point_status}
                    name={point.name}
                    onClick={changePoint(point)}
                    isMeetingDone={isMeetingDone}
                  />
                </Box>
                {idxP + 1 !== theme.points.length && <Divider className={classes.divider} />}
              </React.Fragment>
            ))
          ) : (
            <>
              <Body1 secondary className={classes.point}>
                Nenhum ponto de discussão associado ao tema.
              </Body1>
              <Box height="20px" />
            </>
          )}
        </Box>
      ))}
      <DialogCheckPoint
        open={!isEmpty(selectedPoint)}
        point={selectedPoint}
        setPoint={setSelectedPoint}
      />
    </>
  );
};

ThemeList.defaultProps = {
  isMeetingDone: false,
  users: [],
};

ThemeList.propTypes = {
  themes: PropTypes.array.isRequired,
  isMeetingDone: PropTypes.bool,
  isGroup: PropTypes.bool.isRequired,
  users: PropTypes.array,
};

export default React.memo(ThemeList);
