import React, { useContext, useCallback } from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import {
  ProposalDispatchContext,
  ProposalStateContext,
  ProposalFuncsContext,
} from '../CreateEditProposalsProvider';
import AddProposalHeader from './pieces/AddProposalHeader';
import ProposalDetails from './pieces/ProposalDetails';
import CardFilesUpload from '../../common/CardFilesUpload';
import ProposalFlow from './pieces/ProposalFlow';
import DialogCheck from '../../common/dialogs/DialogCheck';
import { Prompt } from 'react-router-dom';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  marginContainer: {
    marginTop: '54px',
  },
  marginContent: {
    marginTop: '30px',
  },
}));

const FormInfoProposal = ({ change }) => {
  const classes = useStyles();
  const dispatch = useContext(ProposalDispatchContext);
  const updateField = useContext(ProposalFuncsContext);

  const {
    state: {
      name,
      entity,
      amount,
      errors,
      description,
      files,
      theme,
      themes,
      submitting,
      organicUnit,
      hasBudget,
      budgetNumber,
      // budgetValue,
      organicUnits,
      internalLink,
      internalLinkText,
      on_behalf_of,
      reuniaoExecutivaAssembleiaMunicipalFlow,
      discussAM,
    },
    submitProposal,
    loading,
    openWarning,
    changePage,
    cancelLeaving,
    confirmChange,
  } = useContext(ProposalStateContext);

  const headerState = { loading, submitting, errors, submitProposal };
  const detailsState = {
    name,
    entity,
    amount,
    theme,
    themes,
    organicUnit,
    hasBudget,
    budgetNumber,
    reuniaoExecutivaAssembleiaMunicipalFlow,
    discussAM,
    description,
    internalLink,
    internalLinkText,
    on_behalf_of,
    errors,
  };
  const filesState = { files, errors };
  const flowState = { organicUnits, errors };

  const updateEntityField = useCallback(
    (fieldName, fieldValue) => {
      dispatch({
        type: 'UPDATE_ENTITY_FIELD',
        payload: {
          name: fieldName,
          value: fieldValue,
        },
      });
    },
    [dispatch]
  );

  const cancelEdit = useCallback(
    e => {
      e.preventDefault();
      return confirmChange('/propostas');
    },
    [confirmChange]
  );

  return (
    <>
      <Prompt when={change} message={changePage} />
      <Grid container justify="center" className={classes.marginContainer}>
        <Grid item xs={9} md={10} lg={9}>
          <AddProposalHeader state={headerState} />
          <Grid container className={classes.marginContent} spacing={2}>
            <Grid item xs={12} md={6}>
              <ProposalDetails
                state={detailsState}
                updateField={updateField}
                updateEntityField={updateEntityField}
              />
              <Box height="20px" />
              <CardFilesUpload state={filesState} dispatch={dispatch} />
            </Grid>
            <Grid item xs={12} md={6}>
              <ProposalFlow state={flowState} dispatch={dispatch} />
              <Box height="20px" />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogCheck
        open={openWarning}
        msg="Pretende sair do formulário? Os dados inseridos serão perdidos."
        handleCancel={cancelLeaving}
        handleSubmit={cancelEdit}
        labelSubmit="Sair"
      />
    </>
  );

  // Temporary removal of proposal flow
  /* return (
    <>
      <Prompt when={change} message={changePage} />
      <Grid container justify="center" className={classes.marginContainer}>
        <Grid item xs={9} md={10} lg={9}>
          <AddProposalHeader state={headerState} />
          <Grid container className={classes.marginContent} spacing={2}>
            <Grid item xs={12}>
              <ProposalDetails
                state={detailsState}
                updateField={updateField}
                updateEntityField={updateEntityField}
              />
              <Box height="20px" />
              <CardFilesUpload state={filesState} dispatch={dispatch} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogCheck
        open={openWarning}
        msg="Pretende sair do formulário? Os dados inseridos serão perdidos."
        handleCancel={cancelLeaving}
        handleSubmit={cancelEdit}
        labelSubmit="Sair"
      />
    </>
  ); */
};

FormInfoProposal.propTypes = {
  change: PropTypes.bool.isRequired,
};

export default FormInfoProposal;
