import React from 'react';
import { makeStyles, Grid, Box, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../../../../common/typographys/Body1';
import SecretVotesCounterIndividual from './SecretVotesCounterIndividual';
import Subtitle2 from '../../../../common/typographys/Subtitle2';

const useStyles = makeStyles(theme => ({
  boxLabel: {
    backgroundColor: theme.palette.primary[100],
    padding: '16px 14px',
  },
  entryVote: {
    marginBottom: '2px',
  },
  clear: {
    height: '100%',
    backgroundColor: theme.palette.neutrals[1],
  },
  voteLabel: {
    backgroundColor: theme.palette.primary[50],
    padding: '20px 0px 10px 0px',
  },
  disabled: {
    pointerEvents: 'none',
  },
}));

const typeVotes = ['favor', 'against', 'abstention', 'novote'];
const typeVotesTranslation = ['Votos a favor', 'Votos contra', 'Abstenções', 'Escusa de voto'];

const SecretVotesCounter = ({ secretVotes, changeVote, readonly }) => {
  const classes = useStyles();

  return (
    <Paper className={readonly && classes.disabled}>
      <Grid container>
        <Grid item xs={9}>
          <Box className={classes.clear} />
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.voteLabel}>
            <Subtitle2 center>Votos</Subtitle2>
          </Box>
        </Grid>
      </Grid>
      {typeVotes.map((type, idx) => (
        <Grid key={type} container className={classes.entryVote}>
          <Grid item xs={9}>
            <Box className={classes.boxLabel}>
              <Body1>{typeVotesTranslation[idx]}</Body1>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <SecretVotesCounterIndividual
              type={type}
              value={secretVotes[type]}
              changeVote={changeVote}
            />
          </Grid>
        </Grid>
      ))}
    </Paper>
  );
};

SecretVotesCounter.propTypes = {
  secretVotes: PropTypes.object.isRequired,
  changeVote: PropTypes.func.isRequired,
  readonly: PropTypes.bool.isRequired,
};

export default React.memo(SecretVotesCounter);
