import React, { useEffect, useContext, useCallback } from 'react';
import SeeItem from '../../pieces/SeeItem';
import { useDispatch, useSelector } from 'react-redux';
import { getTypology } from '../../../../store/actions/typologiesActions';
import PropTypes from 'prop-types';
import LabelAndValue from '../../../common/forms/LabelAndValue';
import { TypologyDispatchContext } from './CreateEditTypologyProvider';
import EditTypology from './EditTypology';
import { ConfigStateContext, ConfigDispatchContext } from '../../MenuConfigProvider';
import LabelAndHelper from '../../../common/forms/LabelAndHelper';
import BoxList from '../../../common/BoxList';
import { Box } from '@material-ui/core';
import ThemeList from '../../../common/ThemeList';

const SeeEditTypology = ({ info }) => {
  const dispatchRedux = useDispatch();
  const dispatch = useContext(TypologyDispatchContext);
  const dispatchConfig = useContext(ConfigDispatchContext);
  const { typology } = useSelector(state => state.typologies);
  const { editing } = useContext(ConfigStateContext);

  useEffect(() => {
    if (info.isItemTypology) {
      dispatchRedux(getTypology(info.id));
    }
    return () => {
      dispatchRedux({
        type: 'CLEAR_TYPOLOGY',
      });
    };
  }, [dispatchRedux, info]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'name',
        value: info.name,
      },
    });
  }, [info.name, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'id',
        value: info.id,
      },
    });
  }, [info.id, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'type',
        value: typology.is_group ? 'grupo' : 'individual',
      },
    });
  }, [typology.is_group, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'private_voting',
        value: typology.private_voting,
      },
    });
  }, [typology.private_voting, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'color',
        value: typology.color,
      },
    });
  }, [typology.color, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'locals',
        value: typology.locals.map(option => ({
          id: option.id,
          label: option.name,
          value: option.id,
          name: option.name,
        })),
      },
    });
  }, [typology.locals, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'themes',
        value: typology.themes
          ? typology.themes.map(theme =>
              theme.points && theme.points.length > 0 ? theme : { ...theme, points: [] }
            )
          : [],
      },
    });
  }, [typology.themes, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'points',
        value: typology.points ? typology.points : [],
      },
    });
  }, [typology.points, dispatch]);

  useEffect(() => {
    dispatch({
      type: typology.is_group ? 'SET_GROUPS' : 'SET_INDIVIDUALS',
      payload: typology.is_group ? typology.groups : typology.users,
    });
  }, [typology.is_group, typology.groups, typology.users, dispatch]);

  useEffect(() => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'stratifiedThemes',
        value: typology.themes && typology.themes.length > 0,
      },
    });
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: 'stratifiedPoints',
        value: typology.points && typology.points.length > 0,
      },
    });
  }, [typology.points, typology.themes, dispatch]);

  const startEdit = useCallback(
    e => {
      e.preventDefault();
      dispatchConfig({
        type: 'SET_EDITING',
      });
    },
    [dispatchConfig]
  );

  if (editing) {
    return <EditTypology />;
  }

  if (typology.id) {
    return (
      <SeeItem submitEdit={startEdit}>
        <LabelAndValue label="Nome" value={typology.name} />
        <LabelAndValue
          label="Natureza da tipologia"
          value={typology.is_group ? 'Grupos' : 'Utilizadores individuais'}
        />
        <LabelAndValue
          label="Tipo de votação"
          value={
            typology.private_voting
              ? 'Permitir escolha entre votação secreta e pública'
              : 'Apenas votação pública'
          }
        />
        <LabelAndHelper
          tertiary
          label="Participantes"
          helper="Elementos carregados por predefinição para reuniões desta tipologia."
        />
        <Box height="20px" />
        <BoxList items={typology.is_group ? typology.groups : typology.users} />
        <Box height="8px" />
        {typology.themes && typology.themes.length > 0 && (
          <>
            <LabelAndHelper
              tertiary
              label="Temas"
              helper="Temas predefinidos para reuniões desta tipologia."
            />
            <Box height="20px" />
            <ThemeList themes={typology.themes} isGroup={Boolean(typology.is_group)} />
            <Box height="8px" />
          </>
        )}
        {typology.points && typology.points.length > 0 && (
          <>
            <LabelAndHelper
              tertiary
              label="Pontos de discussão"
              helper="Pontos de discussão predefinidos para reuniões desta tipologia."
            />
            <Box height="20px" />
            <BoxList items={typology.points} />
            <Box height="8px" />
          </>
        )}
      </SeeItem>
    );
  }

  return null;
};

SeeEditTypology.propTypes = {
  info: PropTypes.object.isRequired,
};

export default React.memo(SeeEditTypology);
