import React, { useState, useCallback } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import DialogCheckPoint from './dialogs/DialogCheckPoint';
import isEmpty from '../../utils/isEmpty';
import MeetingPointNameAndStatus from './MeetingPointNameAndStatus';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  mainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 10px',
    backgroundColor: theme.palette.neutrals[2],
    marginBottom: '12px',
  },
}));

const MeetingBoxList = ({ points, isMeetingDone, users, isGroup }) => {
  const classes = useStyles();
  const [selectedPoint, setSelectedPoint] = useState({});
  const { id } = useParams();

  const changePoint = useCallback(
    point => e => {
      e.preventDefault();
      // if (!point.default) {
      setSelectedPoint({
        ...point,
        internalLink: point.internal_link || '',
        internalLinkText: point.internal_link_text || '',
        organicUnit: point.organic_unit,
        hasBudget: point.has_budget,
        budgetNumber: point.budget_number,
        // budgetValue: point.budget_value,
        reuniaoExecutivaAssembleiaMunicipalFlow: point.reuniao_executiva_assembleia_municipal_flow,
        discussAM: point.discuss_am,
        meetingPointStatus: point.meeting_point_status,
        meetingUsers: users,
        meetingIsGroup: isGroup,
        meetingId: id,
      });
      // }
    },
    [users, isGroup, id]
  );

  return (
    <>
      {points.map(point => (
        <Box key={point.name} className={classes.mainBox}>
          <MeetingPointNameAndStatus
            identifierCode={point.identifier_code}
            meetingPointStatus={point.meeting_point_status}
            name={point.name}
            onClick={changePoint(point)}
            isMeetingDone={isMeetingDone}
          />
        </Box>
      ))}
      <DialogCheckPoint
        open={!isEmpty(selectedPoint)}
        point={selectedPoint}
        setPoint={setSelectedPoint}
      />
    </>
  );
};

MeetingBoxList.defaultProps = {
  points: [],
  users: [],
};

MeetingBoxList.propTypes = {
  points: PropTypes.array,
  users: PropTypes.array,
  isMeetingDone: PropTypes.bool.isRequired,
  isGroup: PropTypes.bool.isRequired,
};

export default React.memo(MeetingBoxList);
