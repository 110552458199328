import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckboxPicker from '../../../common/forms/CheckboxPicker';

const ReuniaoExecutivaAssembleiaMunicipalFlow = ({ state, updateField }) => {
  const { reuniaoExecutivaAssembleiaMunicipalFlow } = state;

  return (
    <>
      <CheckboxPicker
        label="Proposta para ser discutida em Reunião Executiva"
        checked={reuniaoExecutivaAssembleiaMunicipalFlow}
        onClick={() => updateField('reuniaoExecutivaAssembleiaMunicipalFlow', !reuniaoExecutivaAssembleiaMunicipalFlow)}
      />
      <Box height="12px" />
    </>
  );
};

ReuniaoExecutivaAssembleiaMunicipalFlow.propTypes = {
  state: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
};

export default React.memo(ReuniaoExecutivaAssembleiaMunicipalFlow);
