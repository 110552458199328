import React, { useCallback, useContext, useEffect } from 'react';
import { makeStyles, Input, fade } from '@material-ui/core';
import PropTypes from 'prop-types';
import ApproverButtonsDecision from './ApproverButtonsDecision';
import {
  CheckProposalDispatchContext,
  CheckProposalStateContext,
  CheckProposalFuncsContext,
} from './CheckProposalProvider';
import ApproverAttachments from './ApproverAttachments';
import { useDispatch, useSelector } from 'react-redux';
import { startApprover, startEvaluation } from '../../../store/actions/proposalsActions';

const useStyles = makeStyles(theme => ({
  inputField: {
    marginTop: '10px',
    marginBottom: '16px',
    backgroundColor: theme.palette.neutrals[1],
    padding: '0px',
    boxShadow: `0 20px 20px -10px ${fade(theme.palette.primary[100], 0.7)}`,
  },
  paddingMultiline: {
    padding: '16px 24px',
  },
}));

const CurrentApproverAnswer = ({
  decision,
  submitDecision,
  approverKey,
  ids,
  isLastVersion,
  approverId,
}) => {
  const classes = useStyles();
  const dispatch = useContext(CheckProposalDispatchContext);
  const {
    state: {
      proposal: { status, current_decision, id: versionId, proposal_id },
      attachToProposal,
    },
  } = useContext(CheckProposalStateContext);
  const { updateOriginalProposalField } = useContext(CheckProposalFuncsContext);
  const { id: userId } = useSelector(state => state.auth.user);
  const dispatchRedux = useDispatch();
  const { notes, answer_to, attachments, id } = decision;

  useEffect(() => {
    dispatchRedux(startEvaluation(proposal_id, versionId));
  }, [dispatchRedux, proposal_id, versionId]);

  useEffect(() => {
    if (
      current_decision &&
      current_decision.user &&
      current_decision.user.id === userId &&
      status === 0 &&
      ids.approverId === userId
    ) {
      const promiseStart = new Promise(resolve => {
        dispatchRedux(startApprover(ids.proposalId, ids.versionId, resolve));
      });

      promiseStart.then(() => {
        updateOriginalProposalField('status', 1);
      });
    }
  }, [dispatchRedux, ids, userId, current_decision, updateOriginalProposalField, status]);

  const updateNotes = useCallback(
    value => {
      dispatch({
        type: 'UPDATE_APPROVER_NOTES',
        payload: {
          user: approverKey,
          value,
        },
      });
    },
    [approverKey, dispatch]
  );

  return (
    <>
      <Input
        fullWidth
        multiline
        value={notes}
        onChange={e => updateNotes(e.target.value)}
        className={classes.inputField}
        rows="4"
        rowsMax="15"
        placeholder="Adicionar despacho"
        disableUnderline
        classes={{ inputMultiline: classes.paddingMultiline }}
      />
      <ApproverAttachments
        answered={false}
        approverKey={approverKey}
        idDecision={id}
        attachments={attachments}
        attachToProposal={attachToProposal}
        isLastVersion={isLastVersion}
        approverId={approverId}
      />
      <ApproverButtonsDecision submitDecision={submitDecision} answerTo={answer_to} />
    </>
  );
};

CurrentApproverAnswer.defaultProps = {
  isLastVersion: false,
  approverId: null,
};

CurrentApproverAnswer.propTypes = {
  decision: PropTypes.object.isRequired,
  submitDecision: PropTypes.func.isRequired,
  approverKey: PropTypes.string.isRequired,
  ids: PropTypes.object.isRequired,
  isLastVersion: PropTypes.bool,
  approverId: PropTypes.number,
};

export default React.memo(CurrentApproverAnswer);
