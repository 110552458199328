import React, { useContext, useState, useRef, useCallback, useMemo } from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import DialogConfirmAction from '../../common/dialogs/DialogConfirmAction';
import BoxUpload from '../../common/BoxUpload';
import Subtitle2 from '../../common/typographys/Subtitle2';
import FileBox from '../../common/FileBox';
import { CheckProposalDispatchContext } from './CheckProposalProvider';
import useBooleanToggle from '../../../utils/hooks/useBooleanToggle';
import { downloadProposalDecisionFile } from '../../../store/actions/proposalsActions';
import { useDispatch, useSelector } from 'react-redux';
import Body1 from '../../common/typographys/Body1';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '10px',
  },
  boxFiles: {
    padding: '10px',
    backgroundColor: '#fff',
  },
  noPadding: {
    padding: '0px!important',
    marginTop: '12px',
  },
}));

const ApproverAttachments = ({
  approverKey,
  idDecision,
  attachments,
  answered,
  singleEntry,
  attachToProposal,
  approverId,
  isLastVersion,
  hideBox,
}) => {
  const classes = useStyles();
  const dispatch = useContext(CheckProposalDispatchContext);
  const dispatchRedux = useDispatch();
  const [openModal, toggleModal] = useBooleanToggle();
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const [errorAttached, setErrorAttached] = useState('');
  const filesRef = useRef(null);
  const { id } = useSelector(state => state.auth.user);
  const isSameApprover = useMemo(() => id === approverId, [id, approverId]);

  const handleUpload = useCallback(
    e => {
      e.preventDefault();

      const sameFiles = [];

      const checkFilesPromise = new Promise(resolve => {
        if (filesRef.current.files.length !== 0) {
          Array.from(filesRef.current.files).forEach((x, idx) => {
            if (attachments.find(at => at.name === x.name) !== undefined) {
              sameFiles.push(x.name);
            }
            if (idx === filesRef.current.files.length - 1) {
              resolve(sameFiles);
            }
          });
        }
      });

      checkFilesPromise.then(res => {
        if (res.length > 0) {
          const plural = res.length > 1;
          const pluralS = plural ? 's' : '';
          const pluralM = plural ? 'm' : '';
          const errorMessage = `O${pluralS} ficheiro${pluralS}: ${res.join(
            ', '
          )}, já se encontra${pluralM} adicionado${pluralS}.`;
          setError(errorMessage);
        } else {
          setError('');
        }
        dispatch({
          type: 'ADD_APPROVER_ATTACHMENTS',
          payload: {
            user: approverKey,
            value:
              filesRef.current.files.length !== 0
                ? Array.from(filesRef.current.files).filter(
                    x => attachments.find(f => x.name === f.name) === undefined
                  )
                : [],
          },
        });
      });
    },
    [approverKey, attachments, dispatch]
  );

  const deleteFile = useCallback(
    fileName => e => {
      e.preventDefault();
      setName(fileName);
      toggleModal();
    },
    [toggleModal]
  );

  const downloadFile = useCallback(
    file => e => {
      e.preventDefault();
      if (idDecision) {
        dispatchRedux(downloadProposalDecisionFile(idDecision, file.id, file.name));
      }
    },
    [dispatchRedux, idDecision]
  );

  const confirmDeleteFile = useCallback(
    nameFile => e => {
      e.preventDefault();
      toggleModal();
      dispatch({
        type: 'DELETE_APPROVER_ATTACHMENT',
        payload: {
          user: approverKey,
          name: nameFile,
        },
      });
      setName('');
    },
    [dispatch, approverKey, toggleModal]
  );

  const attachFile = useCallback(
    nameFile => e => {
      e.preventDefault();

      const isAttaching = attachToProposal.find(n => n === nameFile) === undefined;

      if (isAttaching) {
        setErrorAttached('');
        dispatch({
          type: 'ATTACH_TO_PROPOSAL',
          payload: {
            name: nameFile,
          },
        });
      } else {
        setErrorAttached(`O ficheiro ${nameFile} já se encontra na lista de ficheiros a anexar.`);
      }
    },
    [dispatch, attachToProposal]
  );

  const deleteFileAttached = useCallback(
    fileName => e => {
      e.preventDefault();
      dispatch({
        type: 'REMOVE_ATTACH_TO_PROPOSAL',
        payload: {
          name: fileName,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      {attachments && attachments.length > 0 ? (
        <>
          {singleEntry && <Subtitle2 className={classes.label}>Ficheiro(s)</Subtitle2>}
          <Box className={clsx(classes.boxFiles, { [classes.noPadding]: !singleEntry })}>
            {attachments.map(file => (
              <FileBox
                key={file.id || file.name}
                file={file}
                deleteFile={deleteFile}
                remove={(file.id === undefined || file.id === null) && isSameApprover && !answered}
                download={file.id !== undefined && file.id !== null}
                downloadFile={downloadFile}
                attach={(file.id === undefined || file.id === null) && isSameApprover && !answered}
                attachFile={attachFile}
              />
            ))}
            {error.length > 0 && <Body1 error>{error}</Body1>}
          </Box>
          {!answered && isSameApprover && isLastVersion && !hideBox && (
            <>
              <Box height="6px" />
              <BoxUpload filesRef={filesRef} handleUpload={handleUpload} padding />
            </>
          )}
        </>
      ) : (
        !answered &&
        isSameApprover &&
        isLastVersion &&
        !hideBox && <BoxUpload filesRef={filesRef} handleUpload={handleUpload} padding />
      )}
      {attachToProposal && attachToProposal.length > 0 && (
        <>
          <Box height="12px" />
          <Subtitle2 tertiary className={classes.label}>
            Ficheiros a anexar à proposta
          </Subtitle2>
          <Box className={clsx(classes.boxFiles)}>
            {attachToProposal.map(fileName => (
              <FileBox
                key={fileName}
                file={{ name: fileName }}
                remove
                deleteFile={deleteFileAttached}
              />
            ))}
            {errorAttached.length > 0 && <Body1 error>{errorAttached}</Body1>}
          </Box>
        </>
      )}
      <DialogConfirmAction
        open={openModal}
        handleClose={toggleModal}
        handleSubmit={confirmDeleteFile(name)}
        msg={`Deseja eliminar o ficheiro ${name}?`}
        labelSubmit="Eliminar"
      />
    </>
  );
};

ApproverAttachments.defaultProps = {
  attachments: [],
  idDecision: null,
  singleEntry: true,
  attachToProposal: [],
  approverId: null,
  isLastVersion: false,
  hideBox: false,
};

ApproverAttachments.propTypes = {
  approverKey: PropTypes.string.isRequired,
  answered: PropTypes.bool.isRequired,
  idDecision: PropTypes.number,
  attachments: PropTypes.array,
  singleEntry: PropTypes.bool,
  attachToProposal: PropTypes.array,
  approverId: PropTypes.number,
  isLastVersion: PropTypes.bool,
  hideBox: PropTypes.bool,
};

export default React.memo(ApproverAttachments);
