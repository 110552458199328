// @ Packages
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { SnackbarProvider } from 'notistack';
import jwtDecode from 'jwt-decode';
import store from './store/store';

// @ Components
import AppSchema from './components/common/layout/AppSchema';
import Login from './components/auth/login/Login';
import Register from './components/auth/register/Register';
import Dashboard from './components/dashboard/Dashboard';
import Footer from './components/common/layout/Footer';
import Meetings from './components/meetings/Meetings';
import Proposals from './components/proposals/Proposals';
import Configs from './components/configs/Configs';
import NewMeeting from './components/meetings/new/NewMeeting';
import WrapPrepare from './components/meetings/meeting/WrapPrepare';
import WrapMeeting from './components/meetings/meeting/WrapMeeting';
import WrapPlateia from './components/meetings/meeting/WrapPlateia';
import NewProposal from './components/proposals/new/NewProposal';
import CheckProposal from './components/proposals/check/CheckProposal';
import EditMeeting from './components/meetings/edit/EditMeeting';
import PersonalProfile from './components/profile/PersonalProfile';
import ErrorWarning from './components/common/snacks/ErrorWarning';
import ShowSnack from './components/common/snacks/ShowSnack';
import AcceptMeetingInvite from './components/meetings/acceptInvite/AcceptMeetingInvite';
import CheckMeeting from './components/meetings/check/CheckMeeting';

// @ Styling
import { ThemeProvider } from '@material-ui/styles';
import theme from './utils/theme';
import { CssBaseline, makeStyles } from '@material-ui/core';

// @ Utils and store actions
import setAuthToken from './utils/setAuthToken';
import { getUserInfo, setCurrentUser, logoutUser } from './store/actions/authActions';
import PrivateRoute from './components/common/routing/PrivateRoute';
import SocketWrapper from './components/common/SocketWrapper';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from 'date-fns/locale/pt';
import ErrorBoundary from './utils/ErrorBoundary';
import SamlAuth from './components/common/SamlAuth';
import Verify from './components/auth/verify/Verify';
import Recover from './components/auth/recover/Recover';
import Reset from './components/auth/reset/Reset';
import Notifications from './components/notifications/Notifications';
import PendingActions from './components/pending-actions/PendingActions';
import NewSession from './components/meetings/session/NewSession';

const routes = [
  // @ Rotas públicas
  { id: '01', private: false, path: '/', component: Login },
  { id: '02', private: false, path: '/recuperar', component: Recover },
  { id: '03', private: false, path: '/registo', component: Register },
  { id: '04', private: false, path: '/repor-palavra-passe', component: Reset },
  { id: '05', private: false, path: '/reuniao/:id/plateia', component: WrapPlateia },
  { id: '06', private: false, path: '/saml-auth', component: SamlAuth },
  { id: '07', private: false, path: '/verificar', component: Verify },
  // @ Rotas privadas
  { id: '08', private: true, path: '/aceitar-convite/reuniao/:id', component: AcceptMeetingInvite },
  { id: '09', private: true, path: '/acoes-pendentes', component: PendingActions },
  { id: '10', private: true, path: '/configuracoes', component: Configs },
  { id: '11', private: true, path: '/dashboard', component: Dashboard },
  { id: '12', private: true, path: '/notificacoes', component: Notifications },
  { id: '13', private: true, path: '/perfil-pessoal', component: PersonalProfile },
  { id: '14', private: true, path: '/propostas', component: Proposals },
  { id: '15', private: true, path: '/propostas/nova', component: NewProposal },
  { id: '16', private: true, path: '/propostas/nova/:id', component: NewProposal },
  { id: '17', private: true, path: '/propostas/visualizar/:id', component: CheckProposal },
  { id: '18', private: true, path: '/reuniao/:id', component: WrapMeeting },
  { id: '19', private: true, path: '/reunioes', component: Meetings },
  { id: '20', private: true, path: '/reunioes/consultar/:id', component: CheckMeeting },
  { id: '21', private: true, path: '/reunioes/editar/:id', component: EditMeeting },
  { id: '22', private: true, path: '/reunioes/nova', component: NewMeeting },
  { id: '23', private: true, path: '/reunioes/preparar/:id', component: WrapPrepare },
  { id: '24', private: true, path: '/reunioes/nova-sessao/:id', component: NewSession },
];

// * Verifica se existe token
if (localStorage.getItem('jwtToken')) {
  setAuthToken(localStorage.getItem('jwtToken'));
  const decoded = jwtDecode(localStorage.getItem('jwtToken'));

  store.dispatch(getUserInfo());
  store.dispatch(setCurrentUser(decoded));

  // * Confirma se o token está válido
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());

    // * Redirect para página de login
    window.location.href = '/';
  }
}

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
ReactGA.initialize(trackingId, {
  gaOptions: {
    cookieDomain: 'none',
  },
});

const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const useStyles = makeStyles(() => ({
  success: { backgroundColor: '#13a600' },
  error: { backgroundColor: '#be0404' },
  warning: { backgroundColor: '#f66f0d' },
  info: { backgroundColor: '#1976d2' },
}));

const App = () => {
  const classes = useStyles();

  return (
    <Provider store={store}>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
        <Router basename={`${process.env.REACT_APP_ROUTER_BASENAME}`}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              disableWindowBlurListener
              classes={{
                variantSuccess: classes.success,
                variantError: classes.error,
                variantWarning: classes.warning,
                variantInfo: classes.info,
              }}
              dense
            >
              <CssBaseline />
              <SocketWrapper>
                <AppSchema>
                  <ErrorBoundary>
                    {routes.map(route =>
                      route.private ? (
                        <Switch key={route.id}>
                          <PrivateRoute exact {...route} />
                        </Switch>
                      ) : (
                        <Route key={route.id} exact {...route} />
                      )
                    )}
                  </ErrorBoundary>
                </AppSchema>
              </SocketWrapper>
              <Footer />
              <ErrorWarning />
              <ShowSnack />
            </SnackbarProvider>
          </ThemeProvider>
        </Router>
      </MuiPickersUtilsProvider>
    </Provider>
  );
};

export default App;
