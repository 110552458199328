import React, { useCallback, useContext, useMemo, useEffect } from 'react';
import { makeStyles, Box, Collapse, Divider } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import ApproverBoxHeader from './ApproverBoxHeader';
import { useDispatch, useSelector } from 'react-redux';
import {
  submitApproverDecision,
  submitApproverFiles,
  getProposalVersions,
} from '../../../store/actions/proposalsActions';
import { CheckProposalStateContext, CheckProposalFuncsContext } from './CheckProposalProvider';
import isEmpty from '../../../utils/isEmpty';
import CurrentApproverAnswer from './CurrentApproverAnswer';
import Subtitle2 from '../../common/typographys/Subtitle2';
import CommentEntry from './CommentEntry';
import ApproverNotes from './ApproverNotes';
import ApproverAttachments from './ApproverAttachments';
import labelVersion from '../../../utils/labelVersion';

const useStyles = makeStyles(theme => ({
  boxItem: {
    marginBottom: '20px',
  },
  approverContent: {
    padding: '20px',
    backgroundColor: theme.palette.primary[50],
  },
  approverAnswered: {
    backgroundColor: theme.palette.neutrals[1],
  },
  divider: {
    marginTop: '8px',
    marginBottom: '16px',
  },
  labelComment: {
    marginBottom: '10px',
  },
  labelCurrentComment: {
    marginTop: '16px',
  },
  boxComment: {
    padding: '24px',
    backgroundColor: theme.palette.neutrals[1],
  },
  boxSingleComment: {
    padding: '16px 24px',
    backgroundColor: theme.palette.neutrals[1],
  },
  noTopMargin: {
    marginTop: '0px!important',
  },
}));

const ApproverEntry = ({ approver, idx, openIdx, approverKey, open }) => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const {
    state: { proposal, hasVersions, attachToProposal, lastVersionId },
  } = useContext(CheckProposalStateContext);

  const { user, decisions, answered, history } = approver;
  const lastDecision = decisions[decisions.length - 1];
  const { answer } = lastDecision;
  const { updateField } = useContext(CheckProposalFuncsContext);
  const { id: idVersion, proposal_id, current_decision, status } = proposal;
  const { notes, attachments, updated_at } = lastDecision;
  const { id } = useSelector(state => state.auth.user);

  const isCurrentDecision = useMemo(
    () =>
      current_decision &&
      current_decision.user &&
      current_decision.user.id === id &&
      user.id === id &&
      lastVersionId === idVersion,
    [current_decision, user.id, id, idVersion, lastVersionId]
  );

  const isAbleToOpen = useMemo(
    () => answered || isCurrentDecision || (history && history.length > 0),
    [answered, history, isCurrentDecision]
  );

  const isLastVersion = useMemo(() => lastVersionId === idVersion, [lastVersionId, idVersion]);

  useEffect(() => {
    if (isCurrentDecision && openIdx === '') {
      updateField('open', idx);
    }
  }, [isCurrentDecision, openIdx, updateField, idx]);

  const handleChange = useCallback(
    (idxApprover, isOpened) => e => {
      e.preventDefault();

      if (isOpened) {
        return updateField('open', '');
      }

      return updateField('open', idxApprover);
    },
    [updateField]
  );

  let decisionId = '';
  if (!isEmpty(current_decision)) {
    decisionId = current_decision.id;
  }

  const submitDecision = useCallback(
    (decision, userObj) => {
      const approverDecision = {
        answer: decision,
        notes,
      };

      if (!isEmpty(userObj)) {
        approverDecision.userId = userObj.id;
      }

      const submitPromise = new Promise((resolve, reject) =>
        dispatchRedux(
          submitApproverDecision(
            proposal_id,
            decisionId,
            idVersion,
            approverDecision,
            resolve,
            reject
          )
        )
      );

      submitPromise.then(() => {
        if (attachments.length > 0) {
          // @ Existem ficheiros associados
          const formData = new FormData();
          const attachmentsPromise = new Promise(resolve => {
            attachments.forEach((file, index) => {
              formData.append('attachments[][file]', file);
              if (index === attachments.length - 1) {
                if (attachToProposal.length > 0) {
                  attachToProposal.forEach((fileName, idxAttach) => {
                    formData.append('attachToProposal[]', fileName);
                    if (idxAttach === attachToProposal.length - 1) {
                      resolve(formData);
                    }
                  });
                } else {
                  resolve(formData);
                }
              }
            });
          });

          attachmentsPromise.then(formDataRes => {
            const uploadPromise = new Promise((resolve, reject) => {
              const filesLength = attachments.length;
              dispatchRedux(
                submitApproverFiles(
                  proposal_id,
                  decisionId,
                  filesLength,
                  formDataRes,
                  resolve,
                  reject
                )
              );
            });

            uploadPromise
              .then(() => {
                dispatchRedux(getProposalVersions(proposal_id));
              })
              .catch(() => {
                return dispatchRedux({
                  type: 'SHOW_SNACK',
                  payload: {
                    variant: 'error',
                    message: 'Ocorreu um erro ao carregar os ficheiros da decisão.',
                  },
                });
              });
          });
        } else {
          dispatchRedux(getProposalVersions(proposal_id));
        }
      });
    },
    [dispatchRedux, decisionId, idVersion, attachments, proposal_id, notes, attachToProposal]
  );

  return (
    <Box className={classes.boxItem}>
      <ApproverBoxHeader
        idx={idx}
        approver={user}
        answered={answered}
        handleChange={handleChange}
        open={open}
        updatedAt={updated_at}
        answer={answer}
        status={status}
        isAbleToOpen={isAbleToOpen}
        isLastVersion={lastVersionId === idVersion}
      />
      {isAbleToOpen && (
        <Collapse in={open}>
          <Box height="4px" />
          <Box className={clsx(classes.approverContent, { [classes.approverAnswered]: answered })}>
            {history.length === 1 ? (
              <>
                <Subtitle2 className={classes.labelComment}>
                  Despacho {hasVersions && `(Versão - ${labelVersion(history[0].version_idx)})`}
                </Subtitle2>
                <Box className={classes.boxSingleComment}>
                  <ApproverNotes
                    approverKey={approverKey}
                    decision={history[0]}
                    answered={answered}
                    hasVersions={hasVersions}
                    singleEntry
                  />
                </Box>
                {history[0].attachments && history[0].attachments.length > 0 && (
                  <Box height="20px" />
                )}
                <ApproverAttachments
                  approverKey={approverKey}
                  idDecision={history[0].id}
                  attachments={history[0].attachments}
                  answered={answered}
                  approverId={user.id}
                  isLastVersion={isLastVersion}
                  hideBox
                  singleEntry
                />
              </>
            ) : (
              history.length > 1 && (
                <>
                  <Subtitle2 className={classes.labelComment}>Histórico</Subtitle2>
                  <Box className={classes.boxComment}>
                    {history.map((decision, idxDecision) => (
                      <React.Fragment key={decision.id}>
                        <CommentEntry
                          answered
                          approverKey={approverKey}
                          decision={decision}
                          hasVersions={hasVersions}
                        />
                        {idxDecision !== history.length - 1 && (
                          <Divider
                            className={clsx(classes.divider, {
                              [classes.noTopMargin]: decision.attachments.length > 0,
                            })}
                          />
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                </>
              )
            )}
            {!answered && isCurrentDecision && (
              <>
                <Subtitle2 className={classes.labelCurrentComment}>Despacho</Subtitle2>
                <CurrentApproverAnswer
                  submitDecision={submitDecision}
                  decision={lastDecision}
                  approverKey={approverKey}
                  ids={{ proposalId: proposal_id, versionId: idVersion, approverId: user.id }}
                  isLastVersion={isLastVersion}
                  approverId={user.id}
                />
              </>
            )}
          </Box>
        </Collapse>
      )}
    </Box>
  );
};

ApproverEntry.defaultProps = {
  open: false,
};

ApproverEntry.propTypes = {
  approver: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  openIdx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  approverKey: PropTypes.string.isRequired,
  open: PropTypes.bool,
};

export default React.memo(ApproverEntry);
