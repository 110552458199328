import React, { useMemo } from 'react';
import { makeStyles, Divider, Box, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import isEmpty from '../../../utils/isEmpty';
import formatDate from '../../../utils/formatDate';
import Subtitle2 from '../../common/typographys/Subtitle2';
import Body2 from '../../common/typographys/Body2';
import Subtitle1 from '../../common/typographys/Subtitle1';

const useStyles = makeStyles(() => ({
  mainBox: {
    width: '100%',
  },
  divider: {
    marginBottom: '8px',
    marginTop: '6px',
  },
  label: {
    marginBottom: '8px',
  },
  paper: {
    padding: '20px',
  },
}));

const EntryFlow = ({ userIn, userOut, changedBy, time }) => {
  const classes = useStyles();

  const message = useMemo(() => {
    if (isEmpty(userIn)) {
      // @ Utilizador retirado
      return (
        <Body2>
          <Body2 component="span" bold>
            {userOut.name}
          </Body2>{' '}
          removido(a) do fluxo de aprovação da proposta por{' '}
          <Body2 component="span" bold>
            {changedBy.name}
          </Body2>
          .
        </Body2>
      );
    }

    return (
      <Body2>
        <Body2 component="span" bold>
          {changedBy.name}
        </Body2>{' '}
        substituiu{' '}
        <Body2 component="span" bold>
          {userOut.name}
        </Body2>{' '}
        por{' '}
        <Body2 component="span" bold>
          {userIn.name}
        </Body2>{' '}
        no fluxo de aprovação da proposta.
      </Body2>
    );
  }, [userIn, userOut, changedBy]);

  return (
    <Box className={classes.mainBox}>
      {message}
      <Subtitle1 inactive>{formatDate(time, "dd 'de' MMMM 'de' yyyy '|' HH:mm")}</Subtitle1>
    </Box>
  );
};

EntryFlow.defaultProps = {
  userIn: null,
  userOut: null,
  changedBy: null,
};

EntryFlow.propTypes = {
  userIn: PropTypes.object,
  userOut: PropTypes.object,
  changedBy: PropTypes.object,
  time: PropTypes.string.isRequired,
};

const ChangedFlowHistory = ({ changedFlow }) => {
  const classes = useStyles();

  return (
    <>
      <Subtitle2 tertiary className={classes.label}>
        Histórico de alterações
      </Subtitle2>
      <Paper className={classes.paper}>
        {changedFlow.map(({ user_in, user_out, changed_by, created_at }, idx) => (
          <React.Fragment key={created_at}>
            <EntryFlow userIn={user_in} userOut={user_out} changedBy={changed_by} time={created_at} />
            {idx !== changedFlow.length - 1 && <Divider className={classes.divider} />}
          </React.Fragment>
        ))}
      </Paper>
    </>
  );
};

ChangedFlowHistory.propTypes = {
  changedFlow: PropTypes.array.isRequired,
};

export default React.memo(ChangedFlowHistory);
