import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import CheckboxPicker from '../../../common/forms/CheckboxPicker';

const DiscussAm = ({ state, updateField }) => {
  const { discussAM } = state;

  return (
    <>
      <CheckboxPicker
        label="Necessita de aprovação em Assembleia Municipal"
        checked={discussAM}
        onClick={() => updateField('discussAM', !discussAM)}
      />
      <Box height="12px" />
    </>
  );
};

DiscussAm.propTypes = {
  state: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
};

export default React.memo(DiscussAm);
