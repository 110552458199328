import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import CardBlock from '../../../common/forms/CardBlock';
import isEmpty from '../../../../utils/isEmpty';
import BoxOrganicApprover from './BoxOrganicApprover';

const ProposalFlow = ({ state, dispatch }) => {
  const { organicUnits } = state;

  return !isEmpty(organicUnits) ? (
    <CardBlock label="Fluxo de aprovação">
      {organicUnits.map(
        (unit, idx) =>
          !isEmpty(unit) && (
            <React.Fragment key={unit.id}>
              <BoxOrganicApprover
                unit={unit}
                idx={idx}
                total={organicUnits.length}
                dispatch={dispatch}
              />
              {idx !== organicUnits - 1 && <Box height="8px" />}
            </React.Fragment>
          )
      )}
    </CardBlock>
  ) : null;
};

ProposalFlow.propTypes = {
  state: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default React.memo(ProposalFlow);
