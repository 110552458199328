/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
import React from 'react';
import clsx from 'clsx';
import Select from 'react-select';
import { emphasize, makeStyles, useTheme } from '@material-ui/core/styles';
import { FormHelperText, Typography, TextField, Paper, Chip, MenuItem, Divider } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import PropTypes from 'prop-types';
import isEmpty from '../../../utils/isEmpty';
import Subtitle1 from '../typographys/Subtitle1';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: 250,
  },
  input: {
    display: 'flex',
    padding: 0,
    height: 'auto',
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  chipFocused: {
    backgroundColor: emphasize(theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700], 0.08),
  },
  noOptionsMessage: {
    padding: theme.spacing(1, 2),
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    bottom: 6,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
    marginBottom: '50px',
  },
  divider: {
    height: theme.spacing(2),
  },
  marginBottom: {
    marginBottom: '20px',
  },
  marginDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  marginTextfieldDivider: {
    marginBottom: '20px',
  },
  dividerError: {
    backgroundColor: theme.palette.error.main,
  },
  hoverSelected: {
    '&:hover > span': {
      color: `${theme.palette.neutrals[6]}!important`,
    },
  },
  noMargins: {
    margin: '0!important',
  },
  isFocused: {
    backgroundColor: theme.palette.primary[200],
  },
}));

function NoOptionsMessage(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.noOptionsMessage} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
        disableUnderline: true,
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  const classes = useStyles();

  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isSelected}
      component="div"
      className={clsx(classes.hoverSelected, { [classes.isFocused]: props.isFocused && !props.isSelected })}
      {...props.innerProps}
    >
      <Subtitle1 selected={props.isSelected}>{props.children}</Subtitle1>
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  return (
    <Typography color="textSecondary" className={props.selectProps.classes.placeholder} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} variant={props.selectProps.variant || 'body1'} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function MultiValue(props) {
  return (
    <Chip
      tabIndex={-1}
      label={props.children}
      className={clsx(props.selectProps.classes.chip, {
        [props.selectProps.classes.chipFocused]: props.isFocused,
      })}
      onDelete={props.removeProps.onClick}
      deleteIcon={<Close {...props.removeProps} />}
    />
  );
}

MultiValue.propTypes = {
  children: PropTypes.node,
  isFocused: PropTypes.bool,
  removeProps: PropTypes.object.isRequired,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  // Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

const SelectBare = ({
  value,
  options,
  onChange,
  label,
  labelVariant,
  name,
  error,
  helperText,
  placeholder,
  marginDivider,
  hideDivider,
  noMargins,
  ...props
}) => {
  const theme = useTheme();
  const classes = useStyles();

  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    indicatorsContainer: base => ({
      ...base,
      color: theme.palette.neutrals[5],
      '&:hover': {
        cursor: 'pointer',
      },
    }),
    option: (_, { isFocused, isSelected }) => ({
      width: 'auto',
      overflow: 'hidden',
      boxSizing: 'border-box',
      fontStyle: 'normal',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      lineHeight: '24px',
      paddingTop: '12px',
      whiteSpace: 'nowrap',
      fontStretch: 'normal',
      paddingLeft: '20px',
      paddingRight: '20px',
      letterSpacing: 'normal',
      paddingBottom: '8px',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      display: 'flex',
      position: 'relative',
      background: isFocused ? theme.palette.primary[200] : isSelected ? theme.palette.primary[900] : '#eaefef',
      color: isFocused ? '#636363' : isSelected ? '#fff' : '#636363',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textDecoration: 'none',
      border: '0px',
      cursor: 'pointer',
      margin: '0px',
      outline: '0px',
      userSelect: 'none',
      borderRadius: '0px',
      verticalAlign: 'middle',
      fontSize: '12px',
    }),
  };

  return (
    <>
      {label && (
        <Typography variant={labelVariant} color={!isEmpty(error) ? 'error' : 'initial'}>
          {label}
        </Typography>
      )}
      <Select
        classes={classes}
        styles={selectStyles}
        options={options}
        components={{ ...components, ...props.otherComponents }}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        error={!isEmpty(error)}
        menuPortalTarget={document.querySelector('body')}
        {...props}
      />
      {!hideDivider && (
        <Divider
          className={clsx({
            [classes.marginDivider]: marginDivider,
            [classes.marginTextfieldDivider]: isEmpty(error) && !helperText,
            [classes.dividerError]: !isEmpty(error),
            [classes.noMargins]: noMargins,
          })}
        />
      )}

      {!isEmpty(error) || helperText ? (
        <FormHelperText className={classes.marginBottom} error={!isEmpty(error)}>
          {!isEmpty(error) ? error : helperText}
        </FormHelperText>
      ) : null}
    </>
  );
};

SelectBare.defaultProps = {
  label: null,
  error: null,
  helperText: null,
  placeholder: null,
  labelVariant: 'subtitle2',
  value: null,
  marginDivider: true,
  hideDivider: false,
  noMargins: false,
  otherComponents: {},
};

SelectBare.propTypes = {
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  labelVariant: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  marginDivider: PropTypes.bool,
  hideDivider: PropTypes.bool,
  noMargins: PropTypes.bool,
  otherComponents: PropTypes.object,
};

export default SelectBare;
