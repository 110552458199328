import React, { useContext, useMemo, useReducer, useCallback } from 'react';
import { makeStyles, Button, Box } from '@material-ui/core';
import Subtitle2 from '../../common/typographys/Subtitle2';
import PropTypes from 'prop-types';
import { CheckProposalStateContext } from './CheckProposalProvider';
import isEmpty from '../../../utils/isEmpty';
import DialogConfirmAction from '../../common/dialogs/DialogConfirmAction';
import CheckboxPicker from '../../common/forms/CheckboxPicker';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: '10px',
    '&:hover': {
      backgroundColor: theme.palette.primary[100],
      border: 'none',
    },
  },
  label: {
    marginTop: '20px',
  },
}));

const ButtonApprover = ({ label, ...props }) => {
  const classes = useStyles();

  return (
    <Button fullWidth className={classes.button} variant="outlined" color="primary" {...props}>
      {label}
    </Button>
  );
};

ButtonApprover.propTypes = {
  label: PropTypes.string.isRequired,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_INFO':
      return {
        ...state,
        ...action.payload,
      };
    case 'OPEN_MODAL':
      return {
        ...state,
        open: true,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        open: false,
      };
    case 'CLEAR_INFO':
      return {
        ...state,
        user: null,
        decision: '',
        open: false,
        msg: '',
        idx: null,
      };
    default:
      return state;
  }
};

const ApproverButtonsDecision = ({ submitDecision, answerTo }) => {
  const classes = useStyles();
  const {
    state: { answeredApprovers = [] },
  } = useContext(CheckProposalStateContext);
  const emptyAnswerTo = useMemo(() => isEmpty(answerTo), [answerTo]);
  const [state, dispatch] = useReducer(reducer, {
    user: null,
    decision: '',
    open: false,
    msg: '',
    idx: null,
  });

  const { open, user, decision, msg, idx } = state;

  const openModal = useCallback(
    (decisionValue, userValue, responseName) => e => {
      e.preventDefault();
      let msgConfirm = '';

      if (decisionValue === 1) {
        msgConfirm = 'Confirma o envio do despacho e respetivo pedido de revisão ao proponente?';
      } else if (decisionValue === 3 && responseName === undefined) {
        msgConfirm = 'Confirma a aprovação desta proposta?';
      } else if (decisionValue === 3 && responseName !== undefined) {
        msgConfirm = `Confirma o envio da resposta ao pedido de ${responseName}?`;
      } else if (decisionValue === -1) {
        msgConfirm = 'Confirma a reprovação desta proposta?';
      }

      dispatch({
        type: 'SET_INFO',
        payload: {
          user: userValue || null,
          msg: msgConfirm,
          decision: decisionValue,
          open: true,
        },
      });
    },
    []
  );

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();

      submitDecision(decision, user);
      dispatch({ type: 'CLEAR_INFO' });
    },
    [decision, submitDecision, user]
  );

  const clickBox = useCallback(
    idxApprover => e => {
      e.preventDefault();

      if (idx === idxApprover) {
        return dispatch({ type: 'SET_INFO', payload: { idx: null } });
      }

      return dispatch({ type: 'SET_INFO', payload: { idx: idxApprover } });
    },
    [idx]
  );

  const toggleModal = useCallback(
    e => {
      e.preventDefault();
      let msgConfirm = '';
      let decisionValue = '';
      let approver = {};

      if (idx === -1) {
        msgConfirm = 'Confirma o envio do despacho e respetivo pedido de revisão ao proponente?';
        decisionValue = 1;
      }

      if (idx !== null && idx !== -1) {
        approver = answeredApprovers.find((x, index) => idx === index);
        msgConfirm = `Confirma o envio do despacho e respetivo pedido de revisão a ${approver.name}?`;
        decisionValue = 2;
      }

      dispatch({
        type: 'SET_INFO',
        payload: {
          user: approver || null,
          msg: msgConfirm,
          decision: decisionValue,
          open: true,
        },
      });
    },
    [idx, answeredApprovers]
  );

  return (
    <>
      <Box height="10px" />
      {emptyAnswerTo && <ButtonApprover label="Aprovar" onClick={openModal(3)} />}
      <ButtonApprover label="Reprovar" onClick={openModal(-1)} />
      {!emptyAnswerTo && (
        <>
          <Subtitle2 className={classes.label}>Enviar resposta para</Subtitle2>
          <ButtonApprover label={answerTo.name} onClick={openModal(3, null, answerTo.name)} />
        </>
      )}
      {emptyAnswerTo && answeredApprovers.length === 0 && (
        <ButtonApprover label="Enviar para revisão" onClick={openModal(1)} />
      )}
      {emptyAnswerTo && answeredApprovers.length > 0 && (
        <>
          <Subtitle2 className={classes.label}>Pedir revisão a</Subtitle2>
          <Box height="5px" />
          <CheckboxPicker label="Proponente" checked={idx === -1} onClick={clickBox(-1)} />
          {answeredApprovers.map((approver, indexApprover) => (
            <React.Fragment key={approver.id}>
              <Box height="10px" />
              <CheckboxPicker
                label={approver.name}
                checked={indexApprover === idx}
                onClick={clickBox(indexApprover)}
              />
            </React.Fragment>
          ))}
          <Box display="flex" justifyContent="center">
            <ButtonApprover
              label="Enviar pedido de revisão"
              onClick={toggleModal}
              disabled={idx === null}
            />
          </Box>
        </>
      )}
      <DialogConfirmAction
        open={open}
        handleClose={() => dispatch({ type: 'CLOSE_MODAL' })}
        msg={msg}
        labelSubmit="Confirmar"
        handleSubmit={handleSubmit}
      />
    </>
  );
};

ApproverButtonsDecision.defaultProps = {
  answerTo: {},
};

ApproverButtonsDecision.propTypes = {
  submitDecision: PropTypes.func.isRequired,
  answerTo: PropTypes.object,
};

export default React.memo(ApproverButtonsDecision);
