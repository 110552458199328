import React, { useReducer, useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { getEntities } from '../../store/actions/entitiesActions';
import isEmpty from '../../utils/isEmpty';
import checkEmptyObject from '../../utils/checkEmptyObject';
import { createProposal, uploadProposalVersionFiles } from '../../store/actions/proposalsActions';
import { getThemes } from '../../store/actions/themesActions';
import { getUsers } from '../../store/actions/usersActions';
import validEmail from '../../utils/validation/validEmail';
import useBooleanToggle from '../../utils/hooks/useBooleanToggle';

export const ProposalDispatchContext = React.createContext();
export const ProposalFuncsContext = React.createContext();
export const ProposalStateContext = React.createContext();

const transformParents = (obj, arr) => {
  const auxArr = arr;
  if (!isEmpty(obj)) {
    const clone = { ...obj, isApproving: true };
    delete clone.parent;
    auxArr.push(clone);
    if (obj.parent !== null) {
      return transformParents(obj.parent, auxArr);
    }
  }

  return auxArr;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    case 'ADD_FILES':
      return {
        ...state,
        files: [...state.files, ...Array.from(action.payload.value)],
      };
    case 'DELETE_FILE':
      return {
        ...state,
        files: [...state.files.filter(x => x.name !== action.payload.name)],
      };
    case 'UPDATE_APPROVER_ORGANIC_UNIT':
      return {
        ...state,
        organicUnits: state.organicUnits.map((x, idx) =>
          idx === action.payload.idx ? { ...x, user: action.payload.value } : x
        ),
      };
    case 'UPDATE_APPROVER_ORGANIC_UNIT_STATUS':
      return {
        ...state,
        organicUnits: state.organicUnits.map((x, idx) =>
          idx === action.payload.idx ? { ...x, isApproving: action.payload.value } : x
        ),
      };
    case 'SUBMITTING':
      return {
        ...state,
        submitting: true,
        submitted: true,
      };
    case 'NOT_SUBMITTING':
      return {
        ...state,
        submitting: false,
      };
    case 'UPDATE_ENTITY_FIELD':
      return {
        ...state,
        entity: {
          ...state.entity,
          [action.payload.name]: action.payload.value,
        },
      };
    case 'SET_ERROR':
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload,
        },
      };
    case 'CLEAR_STATE':
      return {
        ...state,
        name: '',
        theme: null,
        files: [],
        description: '',
        entity: null,
        organicUnit: null,
        organicUnits: [],
        amount: '',
        submitted: false,
        submitting: false,
        hasBudget: false,
        reuniaoExecutivaAssembleiaMunicipalFlow: true,
        discussAM: false,
        budgetNumber: '',
        // budgetValue: '',
        internalLink: '',
        internalLinkText: '',
        on_behalf_of: null,
        errors: {},
      };
    default:
      return state;
  }
};

const CreateEditProposalsProvider = ({ children, setChange, navigate }) => {
  const { loading } = useSelector(stateRedux => stateRedux.proposals);
  const { parents } = useSelector(stateRedux => stateRedux.organicUnits);
  const [openWarning, toggleOpenWarning] = useBooleanToggle();
  const [nextLocation, setNextLocation] = useState('');
  const dispatchRedux = useDispatch();
  const [state, dispatch] = useReducer(reducer, {
    name: '',
    theme: null,
    files: [],
    description: '',
    proposal_number: null,
    entity: null,
    organicUnit: null,
    organicUnits: [],
    amount: '',
    submitted: false,
    submitting: false,
    hasBudget: false,
    reuniaoExecutivaAssembleiaMunicipalFlow: true,
    discussAM: false,
    budgetNumber: '',
    // budgetValue: '',
    internalLink: '',
    internalLinkText: '',
    on_behalf_of: null,
    errors: {},
  });

  useEffect(() => {
    dispatchRedux(getEntities());
    dispatchRedux(getThemes());
    dispatchRedux(getUsers());
  }, [dispatchRedux]);

  const {
    name,
    submitted,
    organicUnit,
    description,
    proposal_number,
    entity,
    amount,
    theme,
    files,
    reuniaoExecutivaAssembleiaMunicipalFlow,
    discussAM,
    hasBudget,
    // budgetValue,
    budgetNumber,
    organicUnits,
    internalLink,
    internalLinkText,
    on_behalf_of,
    errors,
  } = state;

  useEffect(() => {
    if (!isEmpty(parents)) {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: 'organicUnits',
          value: transformParents(parents, []),
        },
      });
    }
  }, [parents]);

  useEffect(() => {
    if (name.length > 2 && !isEmpty(errors.name)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: '' },
      });
    } else if (submitted && name.length < 3 && isEmpty(errors.name)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { name: 'Insira o nome para a proposta.' },
      });
    }
  }, [submitted, errors.name, name]);

  useEffect(() => {
    if (description.length > 2 && !isEmpty(errors.description)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { description: '' },
      });
    } else if (submitted && description.length < 3 && isEmpty(errors.description)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { description: 'Insira o enquadramento para a proposta.' },
      });
    }
  }, [submitted, errors.description, description]);

  useEffect(() => {
    if (!isEmpty(organicUnit) && !isEmpty(errors.organicUnit)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { organicUnit: '' },
      });
    }
  }, [errors.organicUnit, organicUnit]);

  useEffect(() => {
    if (!isEmpty(entity) && !isEmpty(entity.name) && !isEmpty(errors.entityName)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { entityName: '' },
      });
    }
  }, [errors.entityName, entity]);

  useEffect(() => {
    if (!isEmpty(entity) && !isEmpty(entity.nif) && !isEmpty(errors.entityNif)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { entityNif: '' },
      });
    }
  }, [errors.entityNif, entity]);

  useEffect(() => {
    if (
      !isEmpty(entity) &&
      !isEmpty(entity.email) &&
      validEmail(entity.email) &&
      !isEmpty(errors.entityEmail)
    ) {
      dispatch({
        type: 'SET_ERROR',
        payload: { entityEmail: '' },
      });
    }
  }, [errors.entityEmail, entity]);

  useEffect(() => {
    if (!isEmpty(budgetNumber) && !isEmpty(errors.budgetNumber)) {
      dispatch({
        type: 'SET_ERROR',
        payload: { budgetNumber: '' },
      });
    }

    if (!hasBudget) {
      dispatch({
        type: 'SET_ERROR',
        payload: { budgetNumber: '' },
      });
    }
  }, [errors.budgetNumber, hasBudget, budgetNumber]);

  const confirmChange = useCallback(
    page => {
      navigate(page);
    },
    [navigate]
  );

  const submitProposal = useCallback(
    e => {
      e.preventDefault();
      const localErrors = {};

      dispatch({
        type: 'SUBMITTING',
      });

      if (name.length < 3) {
        localErrors.name = 'Insira o nome para a proposta.';
      }

      if (description.length < 3) {
        localErrors.description = 'Insira o enquadramento para a proposta.';
      }

      if (isEmpty(organicUnit)) {
        localErrors.organicUnit = 'Defina a Unidade Orgânica para a proposta.';
      }

      // if (hasBudget && isEmpty(budgetValue)) {
      //   localErrors.budgetValue = 'Defina o valor do cabimento.';
      // }

      if (hasBudget && isEmpty(budgetNumber)) {
        localErrors.budgetNumber = 'Defina o número do cabimento.';
      }

      if (!isEmpty(entity) && entity.isNew) {
        // @ Validar erros da nova entidade
        if (isEmpty(entity.name)) {
          localErrors.entityName = 'Insira um nome válido para a entidade.';
        }

        if (isEmpty(entity.nif)) {
          localErrors.entityNif = 'Insira um NIF válido para a entidade.';
        }

        if (isEmpty(entity.email)) {
          localErrors.entityEmail = 'Insira um email para a entidade.';
        }

        if (!validEmail(entity.email)) {
          localErrors.entityEmail = 'Insira um email válido para a entidade.';
        }
      }

      if (checkEmptyObject(localErrors).length > 0) {
        dispatch({
          type: 'NOT_SUBMITTING',
        });
        return dispatch({
          type: 'SET_ERROR',
          payload: localErrors,
        });
      }

      const newProposal = {
        name,
        description,
        proposal_number,
        entity,
        amount,
        themeId: theme ? theme.id : null,
        organicUnitId: organicUnit ? organicUnit.id : null,
        // budgetValue,
        hasBudget,
        budgetNumber,
        reuniaoExecutivaAssembleiaMunicipalFlow,
        discussAM,
        organicUnits: organicUnits.map(({ id, isApproving, user }) => ({
          id,
          userId: user.id,
          isApproving,
        })),
        internalLink,
        internalLinkText,
        on_behalf_of,
      };

      const proposalPromise = new Promise((resolve, reject) => {
        dispatchRedux(createProposal(newProposal, resolve, reject));
      });

      return proposalPromise
        .then(res => {
          // @ res é a resposta da promise resolvida no createProposal (assim, obtemos o id da proposta guardada para associar os attachments a ela)
          if (files.length > 0) {
            // @ Existem ficheiros associados a esta proposta
            const attachmentsPromise = new Promise(resolve => {
              const formData = new FormData();
              files.forEach((file, idx) => {
                formData.append('attachments[][file]', file);
                if (idx === files.length - 1) {
                  resolve(formData);
                }
              });
            });

            attachmentsPromise.then(formData => {
              const uploadPromise = new Promise((resolve, reject) => {
                const filesLength = files.length;
                dispatchRedux(
                  uploadProposalVersionFiles(
                    formData,
                    filesLength,
                    res.id,
                    res.version_id,
                    resolve,
                    reject
                  )
                );
              });

              uploadPromise
                .then(() => {
                  dispatch({
                    type: 'CLEAR_STATE',
                  });

                  return confirmChange('/propostas');
                })
                .catch(() => {
                  dispatch({
                    type: 'NOT_SUBMITTING',
                  });
                  return dispatchRedux({
                    type: 'SHOW_SNACK',
                    payload: {
                      variant: 'error',
                      message: 'Ocorreu um erro ao carregar os ficheiros.',
                    },
                  });
                });
            });
          } else {
            dispatch({
              type: 'NOT_SUBMITTING',
            });
            dispatch({
              type: 'CLEAR_STATE',
            });

            return confirmChange('/propostas');
          }

          return null;
        })
        .catch(() => {
          dispatch({
            type: 'SUBMITTING',
          });
          return dispatchRedux({
            type: 'SHOW_SNACK',
            payload: {
              variant: 'error',
              message: 'Ocorreu um erro ao guardar a proposta.',
            },
          });
        });
    },
    [
      name,
      description,
      organicUnit,
      hasBudget,
      budgetNumber,
      entity,
      proposal_number,
      amount,
      theme,
      reuniaoExecutivaAssembleiaMunicipalFlow,
      discussAM,
      organicUnits,
      internalLink,
      internalLinkText,
      on_behalf_of,
      dispatchRedux,
      files,
      confirmChange,
    ]
  );

  const updateField = useCallback((nameInput, value) => {
    dispatch({
      type: 'UPDATE_FIELD',
      payload: {
        name: nameInput,
        value,
      },
    });
  }, []);

  const changePage = useCallback(
    wantedPage => {
      toggleOpenWarning();
      setNextLocation(wantedPage.pathname);
      setChange(false);
      return false;
    },
    [toggleOpenWarning, setChange]
  );

  const cancelLeaving = useCallback(
    e => {
      e.preventDefault();

      toggleOpenWarning();

      setChange(true);
    },
    [setChange, toggleOpenWarning]
  );

  const contextState = {
    state,
    loading,
    submitProposal,
    openWarning,
    nextLocation,
    changePage,
    cancelLeaving,
    confirmChange,
  };

  return (
    <ProposalDispatchContext.Provider value={dispatch}>
      <ProposalFuncsContext.Provider value={updateField}>
        <ProposalStateContext.Provider value={contextState}>
          {children}
        </ProposalStateContext.Provider>
      </ProposalFuncsContext.Provider>
    </ProposalDispatchContext.Provider>
  );
};

CreateEditProposalsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  navigate: PropTypes.func.isRequired,
  setChange: PropTypes.func.isRequired,
};

export default CreateEditProposalsProvider;
