import React from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import H5 from '../../../../common/typographys/H5';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  marginContainer: {
    marginTop: '54px',
  },
  header: {
    marginBottom: '30px',
  },
  buttonConfirm: {
    color: theme.palette.neutrals[1],
    backgroundColor: theme.palette.primary.A400,
    '&:hover': {
      color: theme.palette.neutrals[1],
      backgroundColor: theme.palette.primary.A700,
    },
  },
  buttonEnd: {
    color: theme.palette.neutrals[1],
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      color: theme.palette.neutrals[1],
      backgroundColor: theme.palette.error.darker,
    },
  },
}));

const Title = ({
  title,
  reunionEnd,
  moderator,
  endReunion,
  disabled,
  showAudience,
  startReunion,
  reunionOngoing,
}) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.marginContainer} spacing={3}>
      <Grid item xs={1} />
      <Grid item xs={3}>
        <H5 className={classes.header}>{title}</H5>
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={5}>
        {reunionEnd && <H5>Reunião terminada. Obrigado pela sua atenção.</H5>}
        {moderator && !reunionEnd && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                className={classes.buttonConfirm}
                onClick={startReunion}
                disabled={reunionOngoing}
              >
                {reunionOngoing ? 'A Decorrer' : 'Iniciar Reunião'}
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button fullWidth variant="contained" color="primary" onClick={showAudience}>
                Ver plateia
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="contained"
                className={classes.buttonEnd}
                onClick={endReunion}
                disabled={disabled}
              >
                Encerrar reunião
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item xs={1} />
    </Grid>
  );
};

Title.defaultProps = {
  reunionEnd: false,
  moderator: false,
  disabled: false,
  reunionOngoing: false,
  endReunion: () => null,
  startReunion: () => null,
  showAudience: () => null,
};

Title.propTypes = {
  title: PropTypes.string.isRequired,
  reunionEnd: PropTypes.bool,
  moderator: PropTypes.bool,
  endReunion: PropTypes.func,
  startReunion: PropTypes.func,
  showAudience: PropTypes.func,
  disabled: PropTypes.bool,
  reunionOngoing: PropTypes.bool,
};

export default React.memo(Title);
