import React, { useContext, useCallback } from 'react';
import { makeStyles, Grid, Box } from '@material-ui/core';
import H5 from '../../common/typographys/H5';
import {
  CheckProposalStateContext,
  CheckProposalFuncsContext,
  CheckProposalDispatchContext,
} from './CheckProposalProvider';
import ProposalDetails from '../new/pieces/ProposalDetails';
import ProposalAttachments from './ProposalAttachments';
import ProposalSummary from './ProposalSummary';
import EditProposalButtons from './EditProposalButtons';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: '30px',
  },
}));

const EditProposalDetails = () => {
  const classes = useStyles();
  const {
    state: { editedProposal, errors = {} },
  } = useContext(CheckProposalStateContext);
  const dispatch = useContext(CheckProposalDispatchContext);
  const { updateProposalField } = useContext(CheckProposalFuncsContext);
  const {
    name,
    entity,
    amount,
    themes = [],
    theme,
    organicUnit,
    hasBudget,
    budgetNumber,
    reuniaoExecutivaAssembleiaMunicipalFlow,
    discussAM,
    description,
    proposal_number,
    identifier_code,
    attachments,
    proposal_id,
    internalLink,
    internalLinkText,
    on_behalf_of,
  } = editedProposal;

  const detailsState = {
    name,
    entity,
    amount,
    themes,
    theme,
    organicUnit,
    hasBudget: Boolean(hasBudget),
    budgetNumber,
    reuniaoExecutivaAssembleiaMunicipalFlow: Boolean(reuniaoExecutivaAssembleiaMunicipalFlow),
    discussAM: Boolean(discussAM),
    description,
    proposal_number,
    internalLink,
    internalLinkText,
    on_behalf_of,
    errors,
  };

  const updateEntityField = useCallback(
    (fieldName, fieldValue) => {
      dispatch({
        type: 'UPDATE_ENTITY_FIELD',
        payload: {
          name: fieldName,
          value: fieldValue,
        },
      });
    },
    [dispatch]
  );

  return (
    <Grid item xs={5}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <H5 className={classes.header}>Editar proposta</H5>
          <EditProposalButtons canSubmit />
          <ProposalDetails
            state={detailsState}
            updateField={updateProposalField}
            updateEntityField={updateEntityField}
            editing
          />
          <Box height="20px" />
          <ProposalSummary id={proposal_id} code={identifier_code} />
          <Box height="20px" />
          <ProposalAttachments attachments={attachments} editing />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default React.memo(EditProposalDetails);
