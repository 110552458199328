import React from 'react';
import { makeStyles, Box, Grid } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import PropTypes from 'prop-types';
import Body1 from '../../common/typographys/Body1';
import clsx from 'clsx';
import Subtitle1 from '../../common/typographys/Subtitle1';
import formatDate from '../../../utils/formatDate';
import DecisionChip from './DecisionChip';

const useStyles = makeStyles(theme => ({
  panelHeader: {
    backgroundColor: theme.palette.primary[50],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 10px',
  },
  indexApprover: {
    minWidth: '30px',
    maxWidth: '30px',
    marginRight: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    color: theme.palette.neutrals[6],
    '&:hover': {
      cursor: 'pointer',
    },
    transition: theme.transitions.create(['transform'], {
      duration: theme.transitions.duration.short,
    }),
  },
  iconOpen: {
    transform: 'rotate(180deg)',
  },
  iconClosed: {
    transform: 'rotate(0)',
  },
  iconExpand: {
    marginLeft: '10px',
    display: 'flex',
    alignItems: 'center',
  },
  answered: {
    backgroundColor: theme.palette.primary.A400,
  },
  white: {
    color: theme.palette.neutrals[1],
  },
  hoverable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  boxNameInfo: {
    width: '100%',
    display: 'flex',
  },
  answer: {
    marginLeft: '8px',
  },
  boxNameDate: {
    overflow: 'hidden',
    width: '100%',
  },
}));

const ApproverBoxHeader = ({
  idx,
  approver,
  answered,
  handleChange,
  open,
  updatedAt,
  answer,
  status,
  isAbleToOpen,
  isLastVersion,
}) => {
  const classes = useStyles();

  return (
    <Box
      className={clsx(classes.panelHeader, {
        [classes.answered]: answered,
        [classes.hoverable]: isAbleToOpen,
      })}
      onClick={isAbleToOpen ? handleChange(idx, open) : () => null}
    >
      <Box className={classes.boxNameInfo}>
        <Box className={classes.indexApprover}>
          <Body1 selected={answered}>{idx + 1}º</Body1>
        </Box>
        <Box className={classes.boxNameDate}>
          <Body1 selected={answered} oneLine>
            {approver.name}
          </Body1>
          {answered && updatedAt && (
            <Grid container justify="space-between" alignItems="center">
              <Grid item>
                <Subtitle1 selected>{formatDate(updatedAt, 'dd/MM/yyyy - HH:mm')}</Subtitle1>
              </Grid>
              <Grid item>{isLastVersion && <DecisionChip answer={answer} status={status} />}</Grid>
            </Grid>
          )}
        </Box>
        {isAbleToOpen && (
          <Box className={classes.iconExpand}>
            <ExpandMore
              className={clsx(classes.icon, {
                [classes.iconOpen]: open,
                [classes.iconClosed]: !open,
                [classes.white]: answered,
              })}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

ApproverBoxHeader.defaultProps = {
  updatedAt: null,
  answer: null,
  status: { slug: 'pending' },
  isAbleToOpen: false,
  isLastVersion: false,
};

ApproverBoxHeader.propTypes = {
  idx: PropTypes.number.isRequired,
  approver: PropTypes.object.isRequired,
  answered: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  updatedAt: PropTypes.string,
  answer: PropTypes.number,
  status: PropTypes.object,
  isAbleToOpen: PropTypes.bool,
  isLastVersion: PropTypes.bool,
};

export default React.memo(ApproverBoxHeader);
