import React from 'react';
import { makeStyles, Divider } from '@material-ui/core';
import BoxHeaderFeatures from '../../../pieces/BoxHeaderFeatures';
import RadioPicker from '../../../pieces/RadioPicker';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  divider: {
    marginTop: '12px',
    marginBottom: '12px',
  },
}));

const TypologyType = ({ type, updateField, error }) => {
  const classes = useStyles();

  return (
    <BoxHeaderFeatures header="Tipo de votação" error={error}>
      <RadioPicker
        onClick={() => updateField('private_voting', 1)}
        checked={type === 1}
        label="Permitir escolha entre votação secreta e pública"
      />
      <Divider className={classes.divider} />
      <RadioPicker
        onClick={() => updateField('private_voting', 0)}
        checked={type === 0}
        label="Apenas votação pública"
      />
    </BoxHeaderFeatures>
  );
};

TypologyType.defaultProps = {
  error: null,
};

TypologyType.propTypes = {
  type: PropTypes.string.isRequired,
  updateField: PropTypes.func.isRequired,
  error: PropTypes.string,
};

export default React.memo(TypologyType);
