import React, { useContext, useState, useMemo, useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import H5 from '../../common/typographys/H5';
import Body2 from '../../common/typographys/Body2';
import ApproverEntry from './ApproverEntry';
import { CheckProposalStateContext, CheckProposalDispatchContext } from './CheckProposalProvider';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import canChangeFlow from '../../../utils/permissions/canChangeFlow';
import ProposalFlow from '../new/pieces/ProposalFlow';
import ChangeFlowButtons from './ChangeFlowButtons';
import { updateProposalFlow, getProposalVersions } from '../../../store/actions/proposalsActions';
import isEmpty from '../../../utils/isEmpty';
import ChangedFlowHistory from './ChangedFlowHistory';
import getStatusBySlug from '../../../utils/getStatusBySlug';
import DialogConfirmAction from '../../common/dialogs/DialogConfirmAction';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: '30px',
  },
  label: {
    marginBottom: '8px',
  },
}));

const filterUserChanges = arr =>
  arr.reduce((acc, cur) => {
    if (cur.in === null || cur.out === null) {
      const id = cur.in || cur.out;
      const lengthIn = arr.map(x => x.in === id && x.out === null).filter(Boolean).length;
      const lengthOut = arr.map(x => x.in === null && x.out === id).filter(Boolean).length;
      const idxUserIn = arr.findIndex(x => x.in === id && x.out === null);
      const idxUserOut = arr.findIndex(x => x.in === null && x.out === id);
      if (idxUserIn !== -1 && idxUserOut !== -1) {
        if (lengthIn !== lengthOut) {
          if (lengthIn > lengthOut) {
            const obj = arr[idxUserIn];
            if (acc.indexOf(obj) === -1) {
              acc.push(obj);
            }
          } else {
            const obj = arr[idxUserOut];
            if (acc.indexOf(obj) === -1) {
              acc.push(obj);
            }
          }
        }
        return acc;
      }

      acc.push(cur);
      return acc;
    }

    acc.push(cur);
    return acc;
  }, []);

const FlowApproversList = ({ canEdit, canSubmit, canRemove }) => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const {
    state: {
      approvers,
      open,
      organicUnits,
      flowChanges,
      hasMeetings,
      editedProposal: { proposal_id, changedFlow, status = {} },
    },
  } = useContext(CheckProposalStateContext);
  const { permissions } = useSelector(state => state.auth.user);
  const [openChange, setOpenChange] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const dispatch = useContext(CheckProposalDispatchContext);
  const flowState = useMemo(() => ({ organicUnits }), [organicUnits]);
  const statusSlug = getStatusBySlug(status.slug);

  const confirmChanges = useCallback(e => {
    e.preventDefault();
    setOpenModal(true);
  }, []);

  const submitChanges = useCallback(
    e => {
      e.preventDefault();
      setOpenModal(false);
      const changes = filterUserChanges(flowChanges);

      const updateData = { changes };

      const changesPromise = new Promise(resolve => {
        dispatchRedux(updateProposalFlow(proposal_id, updateData, resolve));
      });

      changesPromise.then(() => {
        dispatch({
          type: 'UPDATE_FIELD',
          payload: {
            name: 'flowChanges',
            value: [],
          },
        });
        dispatchRedux({
          type: 'SHOW_SNACK',
          payload: {
            variant: 'success',
            message: 'Fluxo de aprovação atualizado com sucesso.',
          },
        });
        setOpenChange(false);
        dispatchRedux(getProposalVersions(proposal_id));
      });
    },
    [flowChanges, dispatchRedux, dispatch, proposal_id]
  );

  return (
    <>
      <Grid item xs={4}>
        <Grid container spacing={1} justify="flex-end">
          <Grid item xs={11}>
            <H5 className={classes.header}>Avaliação</H5>
            {!hasMeetings && statusSlug === 'standby' && canChangeFlow(permissions) && (
              <ChangeFlowButtons
                openChange={openChange}
                setOpenChange={setOpenChange}
                submitChanges={confirmChanges}
              />
            )}
            {!openChange ? (
              <>
                <Body2 secondary className={classes.label}>
                  Fluxo de aprovação
                </Body2>
                {Object.keys(approvers).map((approver, idx) => (
                  <ApproverEntry
                    key={approver}
                    approverKey={approver}
                    approver={approvers[approver]}
                    idx={idx}
                    openIdx={open}
                    open={open === idx}
                  />
                ))}
              </>
            ) : (
              <ProposalFlow state={flowState} dispatch={dispatch} />
            )}
            {!isEmpty(changedFlow) && <ChangedFlowHistory changedFlow={changedFlow} />}
          </Grid>
        </Grid>
      </Grid>
      <DialogConfirmAction
        open={openModal}
        labelSubmit="Confirmar"
        handleClose={e => {
          e.preventDefault();
          setOpenModal(false);
        }}
        handleSubmit={submitChanges}
        msg="Confirma a alteração ao fluxo de aprovação?"
      />
    </>
  );
};

FlowApproversList.defaultProps = {
  canEdit: false,
  canSubmit: false,
  canRemove: false,
};

FlowApproversList.propTypes = {
  canEdit: PropTypes.bool,
  canSubmit: PropTypes.bool,
  canRemove: PropTypes.bool,
};

export default React.memo(FlowApproversList);
