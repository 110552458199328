/* eslint-disable no-nested-ternary */
import React, { useCallback } from 'react';
import { makeStyles, Box, useTheme } from '@material-ui/core';
import PropTypes from 'prop-types';
import Body1 from '../../../common/typographys/Body1';
import Subtitle1 from '../../../common/typographys/Subtitle1';
import clsx from 'clsx';
import SelectBare from '../../../common/forms/SelectBare';
import { useSelector } from 'react-redux';
import CheckboxPicker from '../../../common/forms/CheckboxPicker';

const useStyles = makeStyles(theme => ({
  mainBox: {
    padding: '10px',
    display: 'flex',
    backgroundColor: theme.palette.neutrals[2],
  },
  line: {
    width: '100%',
  },
  sideLabel: {
    display: 'flex',
    alignItems: 'center',
    minWidth: '30px!important',
  },
  left: {
    justifyContent: 'flex-start',
    marginRight: '8px',
  },
  right: {
    justifyContent: 'flex-end',
    marginLeft: '8px',
  },
  content: {
    width: '100%',
  },
  approvingLine: {
    marginTop: '8px',
  },
  icon: {
    fill: theme.palette.neutrals[6],
    '&:hover': {
      cursor: 'pointer',
      fill: theme.palette.colorsPalette.RustRed,
    },
  },
  singleValue: {
    width: '100%',
  },
  notApproving: {
    background: theme.props.pattern,
  },
}));

function SingleValue(props) {
  const {
    selectProps: { unitName, isDisabled, menuIsOpen },
  } = props;
  const classes = useStyles();

  return (
    <div>
      <Body1
        secondary={isDisabled || menuIsOpen}
        className={classes.singleValue}
        variant={props.selectProps.variant || 'body1'}
        {...props.innerProps}
      >
        {props.children}
      </Body1>
      <Subtitle1 inactive={isDisabled}>{unitName}</Subtitle1>
    </div>
  );
}

SingleValue.defaultProps = {
  children: null,
  innerProps: {},
};

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

const otherComponents = {
  SingleValue,
};

const BoxOrganicApprover = ({ unit, idx, total, dispatch }) => {
  const classes = useStyles();
  const { id } = useSelector(state => state.auth.user);
  const { name, user, users, isApproving } = unit;
  const theme = useTheme();

  const updateUser = useCallback(
    value => {
      dispatch({
        type: 'UPDATE_APPROVER_ORGANIC_UNIT',
        payload: {
          idx,
          value,
        },
      });
    },
    [dispatch, idx]
  );

  const updateApproving = useCallback(
    value => e => {
      e.preventDefault();
      dispatch({
        type: 'UPDATE_APPROVER_ORGANIC_UNIT_STATUS',
        payload: {
          idx,
          value,
        },
      });
    },
    [dispatch, idx]
  );

  const styles = {
    input: base => ({
      ...base,
      margin: '0px!important',
      padding: '0px!important',
      top: 0,
      position: 'absolute',
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
    menuList: base => ({
      ...base,
      paddingBottom: '0px',
      paddingTop: '0px',
    }),
    indicatorsContainer: base =>
      isApproving && !isSameUser
        ? {
            ...base,
            color: theme.palette.neutrals[5],
            '&:hover': {
              cursor: 'pointer',
            },
          }
        : { display: 'none' },
    option: (_, { isFocused, isSelected }) => ({
      width: 'auto',
      overflow: 'hidden',
      boxSizing: 'border-box',
      fontStyle: 'normal',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      lineHeight: '24px',
      paddingTop: '12px',
      whiteSpace: 'nowrap',
      fontStretch: 'normal',
      paddingLeft: '20px',
      paddingRight: '20px',
      letterSpacing: 'normal',
      paddingBottom: '8px',
      transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      display: 'flex',
      position: 'relative',
      background: isFocused ? theme.palette.primary[200] : isSelected ? theme.palette.primary[900] : '#eaefef',
      color: isFocused ? '#636363' : isSelected ? '#fff' : '#636363',
      alignItems: 'center',
      justifyContent: 'flex-start',
      textDecoration: 'none',
      border: '0px',
      cursor: 'pointer',
      margin: '0px',
      outline: '0px',
      userSelect: 'none',
      borderRadius: '0px',
      verticalAlign: 'middle',
      fontSize: '12px',
    }),
  };

  const isSameUser = user && user.id === id;

  return (
    <>
      <Box className={classes.mainBox}>
        <Box className={clsx(classes.sideLabel, classes.left)}>
          <Body1 secondary={isSameUser || !isApproving}>{idx + 1}º</Body1>
        </Box>
        <Box className={classes.content}>
          <Box className={classes.line}>
            <SelectBare
              value={user}
              options={users.filter(x => x.id !== id)}
              placeholder="Selecionar aprovador desta Unidade Orgânica"
              onChange={val => updateUser(val)}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              noOptionsMessage={() => 'Nenhum utilizador para substituir.'}
              marginDivider={false}
              name="approver"
              noMargins
              isDisabled={isSameUser || !isApproving}
              otherComponents={otherComponents}
              unitName={name}
              styles={styles}
              isClearable={false}
              hideDivider={isSameUser}
            />
          </Box>
          {/* NOTE: A condição idx !== total - 1 remove a opção de retirar este utilizador (o último) do fluxo */}
          {!isSameUser && idx !== total - 1 && (
            <Box className={classes.approvingLine}>
              <CheckboxPicker
                label="Incluir no fluxo de aprovação"
                checked={isApproving}
                onClick={updateApproving(!isApproving)}
                labelProps={{ secondary: isSameUser || !isApproving }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

BoxOrganicApprover.propTypes = {
  unit: PropTypes.object.isRequired,
  idx: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default React.memo(BoxOrganicApprover);
