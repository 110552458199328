import React, { useContext, useEffect, useCallback } from 'react';
import InputField from '../../../common/forms/InputField';
import { TypologyStateContext, TypologyDispatchContext } from './CreateEditTypologyProvider';
import Subtitle2 from '../../../common/typographys/Subtitle2';
import { makeStyles, Box } from '@material-ui/core';
import LabelAndHelper from '../../../common/forms/LabelAndHelper';
import TimeBox from '../../pieces/TimeBox';
import ParticipantsList from './pieces/ParticipantsList';
import TypologyType from './pieces/TypologyType';
import TypologyVotingType from './pieces/TypologyVotingType';
import StratifiedTypes from './pieces/StratifiedTypes';
import TypologyThemes from './pieces/TypologyThemes';
import TypologyPoints from './pieces/TypologyPoints';
import TypologyLocals from './pieces/TypologyLocals';
import { getLocals } from '../../../../store/actions/localActions';
import { useDispatch } from 'react-redux';
import TypologyColor from './pieces/TypologyColor';
import GroupsList from './pieces/GroupsList';

const useStyles = makeStyles(() => ({
  label: {
    marginBottom: '6px',
  },
  labelHeader: {
    marginBottom: '8px',
  },
  caption: {
    marginBottom: '10px',
  },
  paddingLabel: {
    padding: '0px 3px',
  },
}));

const FormInfoTypology = () => {
  const classes = useStyles();
  const {
    state: {
      type,
      stratifiedThemes,
      stratifiedPoints,
      name,
      errors,
      themes,
      points,
      locals,
      color,
      private_voting,
      participants: { individuals, groups },
    },
    editing,
  } = useContext(TypologyStateContext);
  const dispatch = useContext(TypologyDispatchContext);
  const dispatchRedux = useDispatch();

  useEffect(() => {
    if (editing) {
      window.scrollTo(0, 0);
    }
  }, [editing]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'CLEAR_STATE',
      });
      dispatchRedux(getLocals());
    };
  }, [dispatch, dispatchRedux]);

  const updateField = useCallback(
    (nameInput, value) => {
      dispatch({
        type: 'UPDATE_FIELD',
        payload: {
          name: nameInput,
          value,
        },
      });
    },
    [dispatch]
  );

  return (
    <>
      <InputField
        label="Nome"
        name="name"
        value={name}
        placeholder="Ex: Reunião Municipal"
        onChange={e => updateField('name', e.target.value)}
        error={errors.name}
      />
      <TypologyLocals locals={locals} updateField={updateField} />
      <Box height="20px" />
      <Subtitle2 className={classes.labelHeader}>Características</Subtitle2>
      <TypologyType type={type} updateField={updateField} error={errors.type} />
      <TypologyVotingType
        type={private_voting}
        updateField={updateField}
        error={errors.private_voting}
      />
      <Subtitle2 className={classes.label}>Associar cor</Subtitle2>
      <TypologyColor color={color} updateField={updateField} />
      <StratifiedTypes
        stratifiedThemes={stratifiedThemes}
        stratifiedPoints={stratifiedPoints}
        updateField={updateField}
      />
      {type !== '' && (
        <>
          <LabelAndHelper
            label="Participantes"
            helper="Adicione participantes a esta tipologia, selecionando se não existe limite oratório para cada participante."
          />
          <Box height="12px" />
          <TimeBox
            dense
            fixed
            error={errors.participants}
            label={
              type === 'grupo' && (
                <Box display="flex" justifyContent="flex-end" width="100%">
                  <Subtitle2 center className={classes.paddingLabel}>
                    Nº de convocados
                  </Subtitle2>
                </Box>
              )
            }
          >
            {type === 'individual' && <ParticipantsList individuals={individuals} />}
            {type === 'grupo' && <GroupsList groups={groups} />}
          </TimeBox>
          <Box height="20px" />
        </>
      )}
      {stratifiedThemes && <TypologyThemes themes={themes} />}
      {stratifiedPoints && <TypologyPoints points={points} />}
    </>
  );
};

export default FormInfoTypology;
