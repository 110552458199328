import React from 'react';
import { Box, makeStyles, Grid } from '@material-ui/core';
import { HowToVote, RecordVoiceOver } from '@material-ui/icons';
import labelProposal from '../../../../utils/labelProposal';
import Body1 from '../../../common/typographys/Body1';
import Body2 from '../../../common/typographys/Body2';
import ProposalStatusIcon from './ProposalStatusIcon';
import clsx from 'clsx';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  proposalEntry: {
    padding: '12px 14px',
    marginTop: '8px',
    backgroundColor: theme.palette.neutrals[3],
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.primary[100],
    },
  },
  proposalLabelNumber: {
    display: 'flex',
    alignItems: 'center',
  },
  labelProposal: {
    marginLeft: '11px',
  },
  proposalSelected: {
    background: theme.palette.primary[100],
    borderLeft: `6px solid ${theme.palette.primary.A400}`,
    padding: '12px 14px 12px 8px',
    pointerEvents: 'none',
  },
  button: {
    display: 'flex',
  },
  iconContainer: {
    height: '35px',
    width: '35px',
    borderRadius: '100%',
    '&:hover': {
      background: theme.palette.primary[200],
    },
  },
  icon: {
    margin: '5px',
  },
  status: {
    justifyContent: 'center',
  },
  statusContainer: {
    height: '35px',
    width: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ThemePoints = ({
  points,
  handleInfo,
  activeInfo,
  idxTheme,
  handleImmediateVote,
  handleImmediateDiscussion,
  moderator,
}) => {
  const classes = useStyles();
  const onImmediateClick = (point, idxProposal, cb) => e => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    return cb && cb(point, idxTheme, idxProposal)();
  };

  return points.map((point, idxProposal) => (
    <Box
      key={point.id}
      className={clsx(classes.proposalEntry, {
        [classes.proposalSelected]:
          activeInfo.idxProposal === idxProposal && activeInfo.idxTheme === idxTheme,
      })}
      onClick={handleInfo(point, idxTheme, idxProposal)}
    >
      <Grid container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item xs={9} className={classes.proposalLabelNumber}>
          {moderator && (
            <Body1 className={classes.labelProposal}>{labelProposal(idxProposal)}</Body1>
          )}
          {!moderator && (
            <>
              <Body2>{labelProposal(idxProposal)}</Body2>
              <Body1 className={classes.labelProposal}>{point.name}</Body1>
            </>
          )}
        </Grid>
        <Grid item xs={3}>
          <Grid container alignItems="flex-end" spacing={1}>
            {!point?.meeting_point_status?.point_status && (
              <>
                {handleImmediateDiscussion && (
                  <Grid
                    xs={6}
                    item
                    onClick={onImmediateClick(point, idxProposal, handleImmediateDiscussion)}
                    className={classes.button}
                  >
                    <div className={classes.iconContainer}>
                      <RecordVoiceOver className={classes.icon} />
                    </div>
                  </Grid>
                )}
                {!handleImmediateDiscussion && (
                  <Grid xs={6} item className={classes.button}>
                    <div className={classes.iconContainer} />
                  </Grid>
                )}
                {handleImmediateVote && (
                  <Grid
                    xs={6}
                    item
                    onClick={onImmediateClick(point, idxProposal, handleImmediateVote)}
                    className={classes.button}
                  >
                    <div className={classes.iconContainer}>
                      <HowToVote className={classes.icon} />
                    </div>
                  </Grid>
                )}
                {!handleImmediateVote && (
                  <Grid xs={6} item className={classes.button}>
                    <div className={classes.iconContainer} />
                  </Grid>
                )}
              </>
            )}
            {point.meeting_point_status && point.meeting_point_status.point_status && (
              <>
                <Grid xs={6} item />
                <Grid xs={6} item className={clsx(classes.button, classes.status)}>
                  <div className={classes.statusContainer}>
                    <ProposalStatusIcon
                      status={
                        (point.meeting_point_status && point.meeting_point_status.point_status) || 0
                      }
                    />
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  ));
};

ThemePoints.defaultProps = {
  idxTheme: null,
  handleImmediateVote: null,
  handleImmediateDiscussion: null,
};

ThemePoints.propTypes = {
  points: PropTypes.array.isRequired,
  handleInfo: PropTypes.func.isRequired,
  handleImmediateVote: PropTypes.func,
  handleImmediateDiscussion: PropTypes.func,
  activeInfo: PropTypes.object.isRequired,
  idxTheme: PropTypes.number,
  moderator: PropTypes.bool,
};

export default React.memo(ThemePoints);
