import isEmpty from '../isEmpty';

/**
 *
 * @param {object} permissions Permissions object
 * @returns {boolean} True or false depending if user has permission to check pools
 */
const canChangeFlow = permissions => {
  if (!isEmpty(permissions) && permissions.meetings && permissions.meetings.secretary) {
    return true;
  }

  return false;
};

export default canChangeFlow;
