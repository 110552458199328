import React from 'react';
import { makeStyles, fade, Box, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import Subtitle2 from '../../common/typographys/Subtitle2';
import Subtitle1 from '../../common/typographys/Subtitle1';
import Body1 from '../../common/typographys/Body1';
import formatDate from '../../../utils/formatDate';
import labelVersion from '../../../utils/labelVersion';

const useStyles = makeStyles(theme => ({
  inputField: {
    marginTop: '10px',
    marginBottom: '16px',
    backgroundColor: theme.palette.neutrals[1],
    padding: '0px',
    boxShadow: `0 20px 20px -10px ${fade(theme.palette.primary[100], 0.7)}`,
  },
  textBox: {
    width: '100%',
  },
  paddingMultiline: {
    padding: '16px 24px',
  },
  userDate: {
    marginBottom: '10px',
    '& > div': {
      maxWidth: '100%',
    },
  },
  name: {
    marginRight: '10px',
  },
}));

const ApproverNotes = ({ decision, singleEntry, hasVersions }) => {
  const classes = useStyles();
  const { notes, updated_at, user, version_idx } = decision;

  return (
    <>
      <Box className={classes.textBox}>
        {!singleEntry && (
          <Grid container justify="space-between" alignItems="center" className={classes.userDate}>
            <Grid item xs={12}>
              <Subtitle2 oneLine className={classes.name}>
                {user.name}
              </Subtitle2>
            </Grid>
            <Grid item xs={12}>
              <Subtitle1 inactive>
                {formatDate(updated_at, "'A' dd 'de' MMMM 'de' yyyy '|' HH:mm")}
                {hasVersions && ` | ${labelVersion(version_idx)}`}
              </Subtitle1>
            </Grid>
          </Grid>
        )}
        <Body1 secondary={notes === null} printMultiline>
          {notes || 'Nenhum despacho adicionado.'}
        </Body1>
      </Box>
    </>
  );
};

ApproverNotes.propTypes = {
  decision: PropTypes.object.isRequired,
  singleEntry: PropTypes.bool.isRequired,
  hasVersions: PropTypes.bool.isRequired,
};

export default React.memo(ApproverNotes);
