import React, { useContext, useCallback } from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import H5 from '../../common/typographys/H5';
import CardBlock from '../../common/forms/CardBlock';
import { CheckProposalStateContext } from './CheckProposalProvider';
import LabelAndValue from '../../common/forms/LabelAndValue';
import FileBox from '../../common/FileBox';
import { downloadProposalDecisionFile } from '../../../store/actions/proposalsActions';
import { useDispatch, useSelector } from 'react-redux';
import Subtitle2 from '../../common/typographys/Subtitle2';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: '30px',
  },
  label: {
    marginBottom: '8px',
  },
}));

const ReviewProposalNotes = ({ canSubmit, canEdit, canRemove }) => {
  const classes = useStyles();
  const dispatchRedux = useDispatch();
  const {
    state: { proposal },
  } = useContext(CheckProposalStateContext);
  const { decisions = [] } = useSelector(state => state.proposals.decisions);
  const { status } = proposal;
  const lastDecision = decisions[decisions.length - 1] || { user: {} };
  const { id } = lastDecision;

  const downloadFile = useCallback(
    file => e => {
      e.preventDefault();
      if (id) {
        dispatchRedux(downloadProposalDecisionFile(id, file.id, file.name));
      }
    },
    [dispatchRedux, id]
  );

  return (
    <Grid item xs={3}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {lastDecision && status && status.slug === 'correction' && (
            <>
              <H5 className={classes.header}>Pedido de revisão</H5>
              <CardBlock label="Detalhes">
                <LabelAndValue label="Autor" value={lastDecision.user.name} />
                <LabelAndValue label="Despacho" value={lastDecision.notes} />
                {lastDecision.attachments && lastDecision.attachments.length > 0 && (
                  <>
                    <Subtitle2 tertiary className={classes.label}>
                      Ficheiro{lastDecision.attachments.length > 1 ? 's' : ''}
                    </Subtitle2>
                    {lastDecision.attachments.map(file => (
                      <FileBox
                        key={file.id || file.name}
                        file={file}
                        download
                        downloadFile={downloadFile}
                      />
                    ))}
                  </>
                )}
              </CardBlock>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

ReviewProposalNotes.defaultProps = {
  canSubmit: false,
  canEdit: false,
  canRemove: false,
};

ReviewProposalNotes.propTypes = {
  canSubmit: PropTypes.bool,
  canEdit: PropTypes.bool,
  canRemove: PropTypes.bool,
};

export default React.memo(ReviewProposalNotes);
