import React from 'react';
import { makeStyles, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Subtitle1 from '../../common/typographys/Subtitle1';

const useStyles = makeStyles(theme => ({
  box: {
    height: '24px',
    borderRadius: '4px',
    padding: '1px 6px',
  },
  green: {
    backgroundColor: theme.palette.colorsPalette.TrueGreen,
  },
  red: {
    backgroundColor: theme.palette.colorsPalette.RustRed,
  },
  orange: {
    backgroundColor: theme.palette.colorsPalette.Orange,
  },
}));

const DecisionChip = ({ answer, status }) => {
  const classes = useStyles();

  let textAnswer = '';

  if (answer === 3) {
    textAnswer = 'aprovada';
  } else if (answer === -1) {
    textAnswer = 'reprovada';
  } else if (status && status.slug === 'failed') {
    textAnswer = 'reprovada após pedido de revisão';
  } else {
    textAnswer = 'revisão';
  }

  return (
    <Box
      className={clsx(classes.box, {
        [classes.green]: answer === 3,
        [classes.red]: answer === -1,
        [classes.orange]: answer === 1 || answer === 2,
      })}
    >
      <Subtitle1 selected>{textAnswer}</Subtitle1>
    </Box>
  );
};

DecisionChip.defaultProps = {
  answer: null,
  status: { slug: 'pending' },
};

DecisionChip.propTypes = {
  answer: PropTypes.number,
  status: PropTypes.object,
};

export default React.memo(DecisionChip);
