import React from 'react';
import PropTypes from 'prop-types';
import ApproverNotes from './ApproverNotes';
import ApproverAttachments from './ApproverAttachments';

const CommentEntry = ({
  approverKey,
  decision,
  answered,
  singleEntry,
  isLastVersion,
  hasVersions,
}) => {
  return (
    <>
      <ApproverNotes
        approverKey={approverKey}
        decision={decision}
        answered={answered}
        singleEntry={singleEntry}
        hasVersions={hasVersions}
      />
      <ApproverAttachments
        approverKey={approverKey}
        idDecision={decision.id}
        attachments={decision.attachments}
        answered={answered}
        singleEntry={singleEntry}
        isLastVersion={isLastVersion}
      />
    </>
  );
};

CommentEntry.defaultProps = {
  answered: false,
  singleEntry: false,
  isLastVersion: false,
  hasVersions: false,
};

CommentEntry.propTypes = {
  approverKey: PropTypes.string.isRequired,
  decision: PropTypes.object.isRequired,
  answered: PropTypes.bool,
  singleEntry: PropTypes.bool,
  isLastVersion: PropTypes.bool,
  hasVersions: PropTypes.bool,
};

export default React.memo(CommentEntry);
