import React from 'react';
import { makeStyles, Grid, Button, Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  buttonChange: {
    marginBottom: '20px',
  },
  common: {
    width: '100%',
    maxWidth: '253px',
    marginBottom: '46px',
    height: '44px',
  },
  buttonBox: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  button: {
    marginLeft: '10px',
    color: theme.palette.neutrals[1],
    backgroundColor: theme.palette.primary.A400,
    '&:hover': {
      color: theme.palette.neutrals[1],
      backgroundColor: theme.palette.primary.A700,
    },
  },
}));

const ChangeFlowButtons = ({ openChange, setOpenChange, submitChanges }) => {
  const classes = useStyles();

  return (
    <Grid container justify="center" spacing={1} className={classes.buttonChange}>
      {openChange ? (
        <Box className={classes.buttonBox}>
          <Button
            variant="outlined"
            color="primary"
            onClick={e => {
              e.preventDefault();
              setOpenChange(false);
            }}
            className={classes.common}
          >
            Cancelar
          </Button>
          <Button variant="contained" onClick={submitChanges} className={clsx(classes.button, classes.common)}>
            Gravar
          </Button>
        </Box>
      ) : (
        <Button
          variant="contained"
          onClick={e => {
            e.preventDefault();
            setOpenChange(true);
          }}
          className={clsx(classes.button, classes.common)}
        >
          Alterar fluxo
        </Button>
      )}
    </Grid>
  );
};

ChangeFlowButtons.propTypes = {
  openChange: PropTypes.bool.isRequired,
  setOpenChange: PropTypes.func.isRequired,
  submitChanges: PropTypes.func.isRequired,
};

export default React.memo(ChangeFlowButtons);
